module.exports = {
  "BOUTIQUE": "BOUTIQUE",
  "BOUTIQUE_SHORT": "BOUTIQUE_SHORT",
  "BOUTIQUE_NEWSLETTER": "BOUTIQUE_NEWSLETTER",
  "SLOGAN": "SLOGAN",
  "SLOGAN0": "SLOGAN0",
  "SLOGAN1": "SLOGAN1",
  "SLOGAN2": "SLOGAN2",
  "SLOGAN3": "SLOGAN3",
  "SLOGAN4": "SLOGAN4",
  "SLOGAN5": "SLOGAN5",
  "SLOGAN6": "SLOGAN6",
  "SLOGAN7": "SLOGAN7",
  "SLOGAN8": "SLOGAN8",
  "SUSPIROS": "SUSPIROS",
  "SUSPIROS_INTRO": "SUSPIROS_INTRO",
  "PAVLOVAS": "PAVLOVAS",
  "PAVLOVAS_MENU": "PAVLOVAS_MENU",
  "PAVLOVAS_HEAD": "PAVLOVAS_HEAD",
  "PAVLOVAS_INTRO": "PAVLOVAS_INTRO",
  "PERSONALIZADOS": "PERSONALIZADOS",
  "PERSONALIZADOS_MENU": "PERSONALIZADOS_MENU",
  "PERSONALIZADOS_HEAD": "PERSONALIZADOS_HEAD",
  "PERSONALIZADOS_INTRO": "PERSONALIZADOS_INTRO",
  "EVENTOS": "EVENTOS",
  "EVENTOS_MENU": "EVENTOS_MENU",
  "EVENTOS_HEAD": "EVENTOS_HEAD",
  "EVENTOS_INTRO": "EVENTOS_INTRO",
  "NEWS": "NEWS",
  "VANESSA1": "VANESSA1",
  "VANESSA2": "VANESSA2",
  "VANESSA3": "VANESSA3",
  "VANESSA4": "VANESSA4",
  "VANESSA5": "VANESSA5",
  "VANESSA6": "VANESSA6",
  "VANESSA7": "VANESSA7",
  "VANESSA8": "VANESSA8",
  "VANESSA9": "VANESSA9",
  "VANESSA_NAME": "VANESSA_NAME",
  "VANESSA_FULLNAME": "VANESSA_FULLNAME",
  "VANESSA_HEAD": "VANESSA_HEAD",
  "PERSONALIZADOS_CARD": "PERSONALIZADOS_CARD",
  "PAVLOVAS_CARD": "PAVLOVAS_CARD",
  "SUSPIROS_CARD": "SUSPIROS_CARD",
  "EVENTOS_CARD": "EVENTOS_CARD",
  "LEARN_MORE": "LEARN_MORE",
  "COOKIE": "COOKIE",
  "COOKIES": "COOKIES",
  "CONTACT": "CONTACT",
  "CONTACT_US": "CONTACT_US",
  "CONTACT_US1": "CONTACT_US1",
  "CONTACT_HEAD": "CONTACT_HEAD",
  "PHONE": "PHONE",
  "YOUR_PHONE": "YOUR_PHONE",
  "ABOUT": "ABOUT",
  "ABOUTUS": "ABOUTUS",
  "ABOUT_HEAD": "ABOUT_HEAD",
  "FOLLOW_US": "FOLLOW_US",
  "COPYRIGHT": "COPYRIGHT",
  "HOME": "HOME",
  "ESPECIALIDADES": "ESPECIALIDADES",
  "ENVIO": "ENVIO",
  "TIPOS": "TIPOS",
  "NOME": "NOME",
  "SOBRENOME": "SOBRENOME",
  "EMAIL": "EMAIL",
  "EMAIL_ADDRESS": "EMAIL_ADDRESS",
  "EMAIL_ENVIADO": "EMAIL_ENVIADO",
  "EMAIL_ERROR": "EMAIL_ERROR",
  "CELULAR": "CELULAR",
  "RAZAO": "RAZAO",
  "MENSAGEM": "MENSAGEM",
  "ENVIAR": "ENVIAR",
  "SELECIONE": "SELECIONE",
  "ANIVERSARIO": "ANIVERSARIO",
  "BATIZADOS": "BATIZADOS",
  "BODAS": "BODAS",
  "CASAMENTOS": "CASAMENTOS",
  "CHADEBEBE": "CHADEBEBE",
  "CHAREVELACAO": "CHAREVELACAO",
  "CORPORATIVO": "CORPORATIVO",
  "MATERNIDADE": "MATERNIDADE",
  "SOBREMESAS": "SOBREMESAS",
  "OUTROS": "OUTROS",
  "NUMBER": "NUMBER",
  "NUMBERS": "NUMBERS",
  "NUMBERS_HEAD": "NUMBERS_HEAD",
  "TESTEMONY": "TESTEMONY",
  "FORMAS": "FORMAS",
  "FORMAS_MENU": "FORMAS_MENU",
  "FORMAS_HEAD": "FORMAS_HEAD",
  "FORMAS_SUBHEAD": "FORMAS_SUBHEAD",
  "FORMAS_TEXTO1": "FORMAS_TEXTO1",
  "FORMAS_TEXTO2": "FORMAS_TEXTO2",
  "FORMAS_TEXTO3": "FORMAS_TEXTO3",
  "FORMAS_TEXTO4": "FORMAS_TEXTO4",
  "FORMAS_TEXTO5": "FORMAS_TEXTO5",
  "NOVIDADES": "NOVIDADES",
  "WHATSAPP": "WHATSAPP",
  "VOLTAR": "VOLTAR",
  "SIDE_TEXT": "SIDE_TEXT",
  "LEMBRANCINHAS": "LEMBRANCINHAS",
  "LEMBRANCINHA": "LEMBRANCINHA",
  "LEMBRANCINHA_MENU": "LEMBRANCINHA_MENU",
  "SATISFEITO": "SATISFEITO",
  "PIRULITOS": "PIRULITOS",
  "KG": "KG",
  "OVOS": "OVOS",
  "BATEDEIRA": "BATEDEIRA",
  "FORNO": "FORNO",
  "AVALIACOES": "AVALIACOES",
  "AVALIACOES_TITULO": "AVALIACOES_TITULO",
  "AVALIACOES_CLIENTE": "AVALIACOES_CLIENTE",
  "BOUTIQUE_AVALIACOES": "BOUTIQUE_AVALIACOES",
  "PORTFOLIO": "PORTFOLIO",
  "PORTFOLIO_TITULO": "PORTFOLIO_TITULO",
  "BOUTIQUE_PORTFOLIO": "BOUTIQUE_PORTFOLIO",
  "PRODUTO": "PRODUTO",
  "OPCOES": "OPCOES",
  "CLIENTE1": "CLIENTE1",
  "CLIENTE2": "CLIENTE2",
  "CLIENTE3": "CLIENTE3",
  "CLIENTE4": "CLIENTE4",
  "CLIENTE5": "CLIENTE5",
  "CLIENTE6": "CLIENTE6",
  "REVIEW1": "REVIEW1",
  "REVIEW2": "REVIEW2",
  "REVIEW3": "REVIEW3",
  "REVIEW4": "REVIEW4",
  "REVIEW5": "REVIEW5",
  "REVIEW6": "REVIEW6",
  "OPCOES_TOOL": "OPCOES_TOOL",
  "HOME_TOOL": "HOME_TOOL",
  "WHATS_TOOL": "WHATS_TOOL",
  "COUNTRY_TOOL": "COUNTRY_TOOL",
  "COUNTRY1_TOOL": "COUNTRY1_TOOL",
  "ACCEPT": "ACCEPT",
  "DECLINE": "DECLINE",
  "CANCEL": "CANCEL",
  "SIGNUP": "SIGNUP",
  "USERNAME": "USERNAME",
  "CONFIRMATION_CODE": "CONFIRMATION_CODE",
  "CONFIRMATION": "CONFIRMATION",
  "PREENCHER": "PREENCHER",
  "COUNT": "COUNT",
  "NOTFOUND_HEAD": "NOTFOUND_HEAD",
  // "TRACKIQ": "TRACKIQ",
  // "ACCOUNT": "ACCOUNT",
  // "LOGIN": "LOGIN",
  // "ERROR_LOGIN": "ERROR_LOGIN",
  // "FORGOT": "FORGOT",
  // "CHANGE_PASS": "CHANGE_PASS",
  // "PASSWORD": "PASSWORD",
  // "RESET_PASSWORD": "RESET_PASSWORD",
  // "CONFIRMATION": "CONFIRMATION",
  // "CONFIRMATION_HELPER": "CONFIRMATION_HELPER",
  // "LOG": "LOG",
  // "EXIT": "EXIT",
  // "LOGOUT": "LOGOUT",
  // "SUBMIT": "SUBMIT",
  // "PARTNER": "PARTNER",
  // "OEM": "OEM",
  // "FLEET": "FLEET",
  // "FLEET1": "FLEET1",
  // "FLEET2": "FLEET2",
  // "FLEET3": "FLEET3",
  // "FLEET4": "FLEET4",
  // "FLEET5": "FLEET5",
  // "RENTAL": "RENTAL",
  // "FSERVICE": "FSERVICE",
  // "SOLUTIONS": "SOLUTIONS",
  // "SYSTEM": "SYSTEM",
  // "WORK": "WORK",
  // "COMPANY_VALUES": "COMPANY_VALUES",
  // "STORY": "STORY",
  // "VISION": "VISION",
  // "VISION_HEAD": "VISION_HEAD",
  // "MISSION": "MISSION",
  // "MISSION_HEAD": "MISSION_HEAD",
  // "MISSION_HEAD1": "MISSION_HEAD1",
  // "MISSION_HEAD2": "MISSION_HEAD2",
  // "MISSION_HEAD3": "MISSION_HEAD3",
  // "SERVICES": "SERVICES",
  // "PROFESSIONAL": "PROFESSIONAL",
  // "CONSULTING": "CONSULTING",
  // "INTELLIGENCE": "INTELLIGENCE",
  // "ELECTRICALS": "ELECTRICALS",
  // "CORPORATE_FLEET": "CORPORATE_FLEET",
  // "YOUR_NAME": "YOUR_NAME",
  // "YOUR_LASTNAME": "YOUR_LASTNAME",
  // "YOUR_TITLE": "YOUR_TITLE",
  // "YOUR_COMPANY": "YOUR_COMPANY",
  // "YOUR_E_MAIL": "YOUR_E_MAIL",
  // "REASON_FOR_YOUR_CONTACT": "REASON_FOR_YOUR_CONTACT",
  // "YOUR_MESSAGE": "YOUR_MESSAGE",
  // "OTHERS": "OTHERS",
  // "SEND_MESSAGE": "SEND_MESSAGE",
  // "COMPANY_NAME": "COMPANY_NAME",
  // "COMPANY_ADDRESS": "COMPANY_ADDRESS",
  // "CITY": "CITY",
  // "STATE": "STATE",
  // "COUNTRY": "COUNTRY",
  // "ZIP": "ZIP",
  // "EMAIL": "EMAIL",
  // "WHATS": "WHATS",
  // "POLICIES": "POLICIES",
  // "PRIVACY_POLICY": "PRIVACY_POLICY",
  // "COOKIES_POLICY": "COOKIES_POLICY",
  // "TERMS_OF_SERVICE": "TERMS_OF_SERVICE",
  // "OEM1": "OEM1",
  // "OEM2": "OEM2",
  // "OEM3": "OEM3",
  // "OEM4": "OEM4",
  // "OEM5": "OEM5",
  // "OEM6": "OEM6",
  // "OEM7": "OEM7",
  // "OEM8": "OEM8",
  // "NEXT": "NEXT",
  // "PREVIOUS": "PREVIOUS",
  // "CONNECTIVITY": "CONNECTIVITY",
  // "WHAT_WE_DO": "WHAT_WE_DO",
  // "SOME_OF_OUR_WORK": "SOME_OF_OUR_WORK",
  // "STORY_ABOUT_US": "STORY_ABOUT_US",
  // "ABOUT_PART_1_0": "ABOUT_PART_1_0",
  // "ABOUT_PART_1": "ABOUT_PART_1",
  // "ABOUT_PART_2": "ABOUT_PART_2",
  // "ABOUT_PART_2_00": "ABOUT_PART_2_00",
  // "ABOUT_PART_2_0": "ABOUT_PART_2_0",
  // "ABOUT_PART_3": "ABOUT_PART_3",
  // "ABOUT_PART_4_0": "ABOUT_PART_4_0",
  // "ABOUT_PART_4": "ABOUT_PART_4",
  // "ABOUT_PART_5": "ABOUT_PART_5",
  // "ABOUT_PART_6": "ABOUT_PART_6",
  // "ABOUT_PART_7": "ABOUT_PART_7",
  // "ABOUT_PART_8": "ABOUT_PART_8",
  // "ABOUT_PART_9": "ABOUT_PART_9",
  // "COMPANY_VALUES_HEAD": "COMPANY_VALUES_HEAD",
  // "COMPANY_VALUES1": "COMPANY_VALUES1",
  // "COMPANY_VALUES2": "COMPANY_VALUES2",
  // "COMPANY_VALUES3": "COMPANY_VALUES3",
  // "COMPANY_VALUES4": "COMPANY_VALUES4",
  // "COMPANY_VALUES5": "COMPANY_VALUES5",
  // "IT_IS_OUR_COMMITMENT_AND_MARKET": "IT_IS_OUR_COMMITMENT_AND_MARKET",
  // "PASS_MESSAGE": "PASS_MESSAGE",
  // "TELEMATICS": "TELEMATICS",
  // "TELEMATICS_TITLE": "TELEMATICS_TITLE",
  // "TELEMATICS_HEADER": "TELEMATICS_HEADER",
  // "TELEMATICS1": "TELEMATICS1",
  // "TELEMATICS2": "TELEMATICS2",
  // "TELEMATICS3": "TELEMATICS3",
  // "TELEMATICS4": "TELEMATICS4",
  // "TELEMATICS5": "TELEMATICS5",
  // "TELEMATICS6": "TELEMATICS6",
  // "TELEMATICS7": "TELEMATICS7",
  // "TELEMATICS8": "TELEMATICS8",
  // "TCU1": "TCU1",
  // "TCU2": "TCU2",
  // "TCU3": "TCU3",
  // "TCU4": "TCU4",
  // "TCU5": "TCU5",
  // "TCU6": "TCU6",
  // "TCU7": "TCU7",
  // "TCU8": "TCU8",
  // "DONGLES1": "DONGLES1",
  // "DONGLES2": "DONGLES2",
  // "DONGLES3": "DONGLES3",
  // "DONGLES4": "DONGLES4",
  // "DONGLES5": "DONGLES5",
  // "DONGLES6": "DONGLES6",
  // "DONGLES7": "DONGLES7",
  // "DONGLES8": "DONGLES8",
  // "CUSTOMIZATIONS_TITLE": "CUSTOMIZATIONS_TITLE",
  // "CUSTOMIZATIONS_HEAD": "CUSTOMIZATIONS_HEAD",
  // "CUSTOMIZATIONS1": "CUSTOMIZATIONS1",
  // "CUSTOMIZATIONS2": "CUSTOMIZATIONS2",
  // "CUSTOMIZATIONS3": "CUSTOMIZATIONS3",
  // "CUSTOMIZATIONS4": "CUSTOMIZATIONS4",
  // "CUSTOMIZATIONS5": "CUSTOMIZATIONS5",
  // "CUSTOMIZATIONS6": "CUSTOMIZATIONS6",
  // "CUSTOMIZATIONS7": "CUSTOMIZATIONS7",
  // "CUSTOMIZATIONS8": "CUSTOMIZATIONS8",
  // "CUSTOMIZATIONS9": "CUSTOMIZATIONS9",
  // "CUSTOMIZATIONS10": "CUSTOMIZATIONS10",
  // "CUSTOMIZATIONS11": "CUSTOMIZATIONS11",
  // "CUSTOMIZATIONS12": "CUSTOMIZATIONS12",
  // "CUSTOMIZATIONS13": "CUSTOMIZATIONS13",
  // "CUSTOMIZATIONS14": "CUSTOMIZATIONS14",
  // "CUSTOMIZATIONS15": "CUSTOMIZATIONS15",
  // "MOBILITY_SERVICES": "MOBILITY_SERVICES",
  // "MOBILITY_TITLE": "MOBILITY_TITLE",
  // "MOBILITY_HEAD": "MOBILITY_HEAD",
  // "MOBILITY1": "MOBILITY1",
  // "MOBILITY2": "MOBILITY2",
  // "MOBILITY3": "MOBILITY3",
  // "MOBILITY4": "MOBILITY4",
  // "MOBILITY5": "MOBILITY5",
  // "MOBILITY6": "MOBILITY6",
  // "MOBILITY7": "MOBILITY7",
  // "MOBILITY8": "MOBILITY8",
  // "MOBILITY9": "MOBILITY9",
  // "MOBILITY10": "MOBILITY10",
  // "MOBILITY11": "MOBILITY11",
  // "CONNECTED_VEHICLES": "CONNECTED_VEHICLES",
  // "CONNECTED_TITLE": "CONNECTED_TITLE",
  // "CONNECTED_HEADER": "CONNECTED_HEADER",
  // "CONNECTED_CARD": "CONNECTED_CARD",
  // "WHAT1": "WHAT1",
  // "WHAT2": "WHAT2",
  // "WHAT3": "WHAT3",
  // "WHAT4": "WHAT4",
  // "WHAT5": "WHAT5",
  // "WHAT6": "WHAT6",
  // "WHAT7": "WHAT7",
  // "WHAT8": "WHAT8",
  // "CLOUD": 'CLOUD',
  // "DESIGN": 'DESIGN',
  // "APP_SYSTEM_DESIGN": "APP_SYSTEM_DESIGN",
  // "UNIQUE_DESIGN": "UNIQUE_DESIGN",
  // "FOR_ALL_DEVICES": "FOR_ALL_DEVICES",
  // "HEADUNITS": "HEADUNITS",
  // "TABLETS": "TABLETS",
  // "SPECIAL": "SPECIAL",
  // "OS": "OS",
  // "DESIGN_TECHNOLOGY": "DESIGN_TECHNOLOGY",
  // "DESIGN_HEAD": "DESIGN_HEAD",
  // "DESIGN1": "DESIGN1",
  // "DESIGN2": "DESIGN2",
  // "DESIGN3": "DESIGN3",
  // "DESIGN4": "DESIGN4",
  // "DESIGN_APP1": "DESIGN_APP1",
  // "DESIGN_APP2": "DESIGN_APP2",
  // "DESIGN_APP3": "DESIGN_APP3",
  // "DESIGN_APP4": "DESIGN_APP4",
  // "DESIGN_APP5": "DESIGN_APP5",
  // "DESIGN_APP6": "DESIGN_APP6",
  // "DESIGN_APP7": "DESIGN_APP7",
  // "DESIGN_APP8": "DESIGN_APP8",
  // "OUR_APP": "OUR_APP",
  // "DESIGN_OUR_APP1": "DESIGN_OUR_APP1",
  // "DESIGN_OUR_APP2": "DESIGN_OUR_APP2",
  // "DESIGN_OUR_APP3": "DESIGN_OUR_APP3",
  // "DESIGN_OUR_APP4": "DESIGN_OUR_APP4",
  // "DESIGN_OUR_APP5": "DESIGN_OUR_APP5",
  // "DESIGN_OUR_APP6": "DESIGN_OUR_APP6",
  // "DESIGN_OUR_APP7": "DESIGN_OUR_APP7",
  // "DESIGN_OUR_APP8": "DESIGN_OUR_APP8",
  // "ARTIFICIAL_INTELLIGENCE": "ARTIFICIAL_INTELLIGENCE",
  // "ARTIFICIAL_INTELLIGENCE_AI_S": "ARTIFICIAL_INTELLIGENCE_AI_S",
  // "AI1": "AI1",
  // "AI2": "AI2",
  // "AI3": "AI3",
  // "AI4": "AI4",
  // "AI5": "AI5",
  // "AI6": "AI6",
  // "AI7": "AI7",
  // "AI8": "AI8",
  // "AI9": "AI9",
  // "INTELLIGENCE_TITLE": "INTELLIGENCE_TITLE",
  // "INTELLIGENCE_HEAD": "INTELLIGENCE_HEAD",
  // "INTELLIGENCE1": "INTELLIGENCE1",
  // "INTELLIGENCE2": "INTELLIGENCE2",
  // "INTELLIGENCE3": "INTELLIGENCE3",
  // "INTELLIGENCE4": "INTELLIGENCE4",
  // "INTELLIGENCE5": "INTELLIGENCE5",
  // "INTELLIGENCE6": "INTELLIGENCE6",
  // "INTELLIGENCE7": "INTELLIGENCE7",
  // "INTELLIGENCE8": "INTELLIGENCE8",
  // "INTELLIGENCE9": "INTELLIGENCE9",
  // "INTELLIGENCE10": "INTELLIGENCE10",
  // "INTELLIGENCE11": "INTELLIGENCE11",
  // "TECHNOLOGY": "TECHNOLOGY",
  // "MACHINE_LEARNING": "MACHINE_LEARNING",
  // "MACHINE_LEARNING_FOR_THE_OEM_W": "MACHINE_LEARNING_FOR_THE_OEM_W",
  // "ML1": "ML1",
  // "ML2": "ML2",
  // "ML3": "ML3",
  // "ML4": "ML4",
  // "ML5": "ML5",
  // "ML6": "ML6",
  // "ML7": "ML7",
  // "ML8": "ML8",
  // "ML9": "ML9",
  // "PROFESSIONAL_TITLE": "PROFESSIONAL_TITLE",
  // "PROFESSIONAL_HEAD": "PROFESSIONAL_HEAD",
  // "PROFESSIONAL_CARD": "PROFESSIONAL_CARD",
  // "PROFESSIONAL1": "PROFESSIONAL1",
  // "PROFESSIONAL2": "PROFESSIONAL2",
  // "PROFESSIONAL3": "PROFESSIONAL3",
  // "PROFESSIONAL4": "PROFESSIONAL4",
  // "PROFESSIONAL5": "PROFESSIONAL5",
  // "PROFESSIONAL6": "PROFESSIONAL6",
  // "PROFESSIONAL7": "PROFESSIONAL7",
  // "PROFESSIONAL8": "PROFESSIONAL8",
  // "PROFESSIONAL9": "PROFESSIONAL9",
  // "CONSULTING_TITLE": "CONSULTING_TITLE",
  // "CONSULTING_HEAD": "CONSULTING_HEAD",
  // "CONSULTING1": "CONSULTING1",
  // "CONSULTING2": "CONSULTING2",
  // "CONSULTING3": "CONSULTING3",
  // "CONSULTING4": "CONSULTING4",
  // "CONSULTING5": "CONSULTING5",
  // "CONSULTING6": "CONSULTING6",
  // "CONSULTING7": "CONSULTING7",
  // "CONSULTING8": "CONSULTING8",
  // "CONSULTING9": "CONSULTING9",
  // "CONSULTING10": "CONSULTING10",
  // "CONSULTING11": "CONSULTING11",
  // "ELECTRIC_HEAD": "ELECTRIC_HEAD",
  // "ELECTRIC1": "ELECTRIC1",
  // "ELECTRIC2": "ELECTRIC2",
  // "ELECTRIC3": "ELECTRIC3",
  // "ELECTRIC4": "ELECTRIC4",
  // "ELECTRIC5": "ELECTRIC5",
  // "ELECTRIC6": "ELECTRIC6",
  // "ELECTRIC7": "ELECTRIC7",
  // "ELECTRIC8": "ELECTRIC8",
  // "ELECTRIC9": "ELECTRIC9",
  // "ELECTRIC10": "ELECTRIC10",
  // "ELECTRIC11": "ELECTRIC11",
  // "CORPORATE": "CORPORATE",
  // "CORPORATE_TITLE": "CORPORATE_TITLE",
  // "CORPORATE_HEAD": "CORPORATE_HEAD",
  // "CORPORATE1": "CORPORATE1",
  // "CORPORATE2": "CORPORATE2",
  // "CORPORATE3": "CORPORATE3",
  // "CORPORATE4": "CORPORATE4",
  // "CORPORATE5": "CORPORATE5",
  // "CORPORATE6": "CORPORATE6",
  // "CORPORATE7": "CORPORATE7",
  // "CORPORATE8": "CORPORATE8",
  // "CORPORATE9": "CORPORATE9",
  // "CORPORATE10": "CORPORATE10",
  // "CORPORATE11": "CORPORATE11",
  // "CORPORATE12": "CORPORATE12",
  // "CORPORATE13": "CORPORATE13",
  // "CORPORATE14": "CORPORATE14",
  // "CORPORATE15": "CORPORATE15",
  // "CORPORATE16": "CORPORATE16",
  // "CORPORATE17": "CORPORATE17",
  // "CORPORATE18": "CORPORATE18",
  // "CORPORATE18_1": "CORPORATE18_1",
  // "CORPORATE18_2": "CORPORATE18_2",
  // "CORPORATE18_3": "CORPORATE18_3",
  // "CORPORATE18_4": "CORPORATE18_4",
  // "CORPORATE19": "CORPORATE19",
  // "CORPORATE20": "CORPORATE20",
  // "CORPORATE21": "CORPORATE21",
  // "CORPORATE22": "CORPORATE22",
  // "CORPORATE23": "CORPORATE23",
  // "CORPORATE24": "CORPORATE24",
  // "RENTAL_CAR": "RENTAL_CAR",
  // "RENTAL_HEAD": "RENTAL_HEAD",
  // "RENTAL_TITLE": "RENTAL_TITLE",
  // "RENTAL0": "RENTAL0",
  // "RENTAL1": "RENTAL1",
  // "RENTAL2": "RENTAL2",
  // "RENTAL3": "RENTAL3",
  // "RENTAL4": "RENTAL4",
  // "RENTAL5": "RENTAL5",
  // "RENTAL6": "RENTAL6",
  // "RENTAL7": "RENTAL7",
  // "RENTAL8": "RENTAL8",
  // "RENTAL9": "RENTAL9",
  // "RENTAL10": "RENTAL10",
  // "RENTAL11": "RENTAL11",
  // "RENTAL12": "RENTAL12",
  // "RENTAL13": "RENTAL13",
  // "RENTAL14": "RENTAL14",
  // "RENTAL15": "RENTAL15",
  // "RENTAL16": "RENTAL16",
  // "RENTAL17": "RENTAL17",
  // "RENTAL18": "RENTAL18",
  // "RENTAL19": "RENTAL19",
  // "RENTAL20": "RENTAL20",
  // "RENT1": "RENT1",
  // "RENT2": "RENT2",
  // "RENT3": "RENT3",
  // "RENT4": "RENT4",
  // "RENT5": "RENT5",
  // "RENT6": "RENT6",
  // "SOLUTION1": "SOLUTION1",
  // "SOLUTION2": "SOLUTION2",
  // "SOLUTION3": "SOLUTION3",
  // "SOLUTION4": "SOLUTION4",
  // "SOLUTION5": "SOLUTION5",
  // "SOLUTION6": "SOLUTION6",

};
