import React, {useState, useEffect } from 'react';
import i18n from 'i18next';
//import { Trans } from 'react-i18next';
import k from "../i18n/keys";
import ReactGA from 'react-ga4';
import { makeStyles } from "@material-ui/core";
import { Paper, Typography } from '@mui/material';
//import { purple } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
//import CardActions from '@mui/material/CardActions';
//import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { FooterContainer } from '../containers/footer';

import img_en from './../assets/images/specialties_en.png';
import img1 from './../assets/images/bds1.png';
import img2 from './../assets/images/bds2.png';
import img3 from './../assets/images/bds3.png';
import img4 from './../assets/images/bds4.png';


const useStyles = makeStyles(theme => ({
    appMain: {
        // paddingLeft: '250px',
        // width: '100%'
    },
    root: {
        backgroundColor: '#fafafa',
    },
    btn: {
        padding: theme.spacing(1)
    },
    menu: {
        display: 'flex',
        top: '60px',
        position: 'fixed',
        width: '80%',
        zIndex: 2,
        backgroundColor: '#fafafa',
    },
    splash: {
        width: '60%',
        height: 'auto',
        // position: 'absolute',
        // top: '13rem',
        // left: '20%',
        opacity: '0.7',
        margin: '20px auto 0 auto',
        '@media(max-width: 700px)': {
            width: '90%',
            left: '5%',
        }
    },
    splashImage: {
        width: '60%',
        height: 'auto',
        // position: 'absolute',
        // top: '13rem',
        // left: '20%',
        opacity: '0.7',
        maxWidth: '100%',
        '@media(max-width: 700px)': {
            width: '90%',
            left: '5%',
        }
    },
    headline: {
        textAlign: 'center',
        padding: '5px',
        // marginTop: '47vw',
        fontSize: '2rem',
        width: '100%',
        '@media(max-width: 820px)': {
            fontSize: '1.8rem',
            // marginTop: '60vw',
        },
        '@media(max-width: 420px)': {
            fontSize: '1.5rem',
            // marginTop: '105vw',
        },
    },
    headline1: {
        color: 'black',
        fontSize: '30px',
        position: 'absolute',
        left: '6%',
        top: '14rem',
        '@media(max-width: 700px)': {
            // fontSize: '30px',
            // top: '8rem',
            // left: '3%',
            color: '#fafafa',
        }
    },
    imgFluid: {
        display: 'block',
        maxWidth: '80%',
        height: '350px',
        width: 'auto',
        margin: '0 auto',
        opacity: '0.6',
        padding: '10px 0 10px 0',
        '@media(max-width: 420px)': {
            maxWidth: '80%',
            height: '250px',
            width: 'auto',
        }
    },
    cardItem: {
        // background: '#6a219a',
        background: 'linear-gradient(-45deg, #6a219a, white, white, #6a219a)',
        textAlign: 'center',
        color: '#ac9551',
        fontWeight: 'bold',
    },
    cardContent: {
        color: '#6a219a',
        margin: '0px',
        fontSize: '1.05rem',
    },
    cardButton: {
        color: '#ac9551',
        fontWeight: 'bold',
        display: 'block',
        margin: '0 auto!important',
        textDecoration: 'none',
        textShadow: '1px 1px 2px #6a219a',
    },
}))




export default function Cards() {

    const classes = useStyles();
    const [show, setShow] = useState(false);
    const url = window.location.href.split('#/')[1];
    useEffect(() => {
        if (url === 'cards') {
            setShow(true);
        }
        ReactGA.send({ hitType: "pageview", page: "/especialidades", title: "Boutique Especialidades" });
        ReactGA.event({
            category: "Boutique Especialidades",
            action: "User Access to Especialidades",
        });

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        display: 'block',
                        marginTop: show ? '78px': '50px',
                        marginBottom: '0px',
                        // position: 'absolute',
                        // top: '5.5rem',
                        maxWidth: '100%',
                        width: '100%',
                        height: 80,
                    },
                }}
            >
                <Paper elevation={12} sx={{ display: 'flex', textAlign: 'center' }} className="paper-back">
                    <Typography className="title" variant="h4">{i18n.t(k.ESPECIALIDADES)}</Typography>
                    <Typography className="subtitle" variant="h5">{i18n.t(k.TIPOS)}</Typography>
                </Paper>
            </Box>
            <img src={img_en} alt={'Especialidades'} className={classes.imgFluid}></img>
            <Box className="home-cards">
                <Card className="home-card" sx={{ maxWidth: 500, background: '#eee2f5', height: { xs: 'auto', xl: '750px' }, boxShadow: 3, marginLeft: { md: '10px' } }}>
                    <Typography
                        className={classes.cardItem}
                        gutterBottom
                        variant="h5"
                        component="div"
                    >
                        {i18n.t(k.SUSPIROS)}
                    </Typography>
                    <CardMedia
                        sx={{ height: 500, opacity: 1 }}
                        image={img1}
                        title="LEMBRANCINHAS"
                    />
                    <CardContent>
                        <Typography
                            variant="body1"
                            className={classes.cardContent}>
                            {i18n.t(k.SUSPIROS_INTRO)}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <a href="#/lembrancinhas" size="small" className={classes.cardButton}>{i18n.t(k.SUSPIROS)}</a>
                    </CardActions>
                </Card>

                <Card className="home-card" sx={{ maxWidth: 500, background: '#eee2f5', height: { xs: 'auto', xl: '750px' }, boxShadow: 3 }}>
                    <Typography
                        className={classes.cardItem}
                        gutterBottom
                        variant="h5"
                        component="div"
                    >
                        {i18n.t(k.PAVLOVAS)}
                    </Typography>
                    <CardMedia
                        sx={{ height: 500, opacity: 1 }}
                        image={img2}
                        title="Pavlovas"
                    />
                    <CardContent>
                        <Typography
                            variant="body1"
                            className={classes.cardContent}>
                            {i18n.t(k.PAVLOVAS_INTRO)}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <a href="#/pavlovas" size="small" className={classes.cardButton}>{i18n.t(k.PAVLOVAS)}</a>
                    </CardActions>
                </Card>

                <Card className="home-card" sx={{ maxWidth: 500, background: '#eee2f5', height: { xs: 'auto', xl: '750px' }, boxShadow: 3 }}>
                    <Typography
                        className={classes.cardItem}
                        gutterBottom
                        variant="h5"
                        component="div"
                    >
                        {i18n.t(k.PERSONALIZADOS)}
                    </Typography>
                    <CardMedia
                        sx={{ height: 500, opacity: 1 }}
                        image={img3}
                        title="Personalizados"
                    />
                    <CardContent>
                        <Typography
                            variant="body1"
                            className={classes.cardContent}>
                            {i18n.t(k.PERSONALIZADOS_INTRO)}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <a href="#/personalizados" size="small" className={classes.cardButton}>{i18n.t(k.PERSONALIZADOS)}</a>
                    </CardActions>
                </Card>

                <Card className="home-card" sx={{ maxWidth: 500, background: '#eee2f5', height: { xs: 'auto', xl: '750px' }, boxShadow: 3, marginRight: { sm: '10px', md: '10px' } }}>
                    <Typography
                        className={classes.cardItem}
                        gutterBottom
                        variant="h5"
                        component="div"
                    >
                        {i18n.t(k.EVENTOS)}
                    </Typography>
                    <CardMedia
                        sx={{ height: 500, opacity: 1 }}
                        image={img4}
                        title="Eventos"
                    />
                    <CardContent>
                        <Typography
                            variant="body1"
                            className={classes.cardContent}>
                            {i18n.t(k.EVENTOS_INTRO)}
                        </Typography>
                    </CardContent>
                    <CardActions >
                        <a href="#/eventos" size="small" className={classes.cardButton}>{i18n.t(k.EVENTOS)}</a>
                    </CardActions>
                </Card>

            </Box>
            {show ? <FooterContainer className="footer--pin" /> : null}
        </>
    );
};