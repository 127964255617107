import React, { useState, useEffect } from 'react';
import localforage from 'localforage';
import emailjs from 'emailjs-com';
import i18n from 'i18next';
import k from "../i18n/keys";
import ReactGA from 'react-ga4';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import Typography from "@material-ui/core/Typography";
import { FooterContainer } from '../containers/footer';
import logo from './../assets/images/logo.png';
import logo_puro from './../assets/images/logo_puro.png';
import ModalMessage from './ModalMessage';

export default function News() {

    const [showPopup, setShowPopup] = useState(false);

    const [confirmationCode, setConfirmationCode] = useState('');
    const [confirmationSent, setConfirmationSent] = useState(false);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/news", title: "Boutique News" });
        ReactGA.event({
            category: "Boutique News",
            action: "User Access to News",
        });

    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    useEffect(() => {
        // Verifica se o usuário já se inscreveu
        const hasSubscribed = localStorage.getItem('hasSubscribed');
        //console.log('HasSubs - ', typeof(hasSubscribed), hasSubscribed);

        localforage.iterate(function (value, key, iterationNumber) {
            // Resulting key/value pair -- this callback
            // will be executed for every item in the
            // database.
            console.log([key, value]);
        }).then(function () {
            //console.log('Iteration has completed');
        }).catch(function (err) {
            // This code runs if there were any errors
            console.log(err);
        });

        // Se o usuário não se inscreveu, exibe o pop-up após um certo tempo (por exemplo, 5 segundos)
        if (hasSubscribed !== 'null') {
            const timeoutId = setTimeout(() => {
                setShowPopup(true);
            }, 2000);

            // Limpa o timeout ao desmontar o componente
            return () => clearTimeout(timeoutId);
        }
    }, []);

    const handleSubscribe = async (event, email, username) => {

        localStorage.setItem('hasSubscribed', 'true');
        localforage.setItem(username, email, function (err) {
            // if err is non-null, we got an error
             localforage.getItem(username, function (err, email) {
                // if err is non-null, we got an error. otherwise, value is the value
            })
        });

        const generatedCode = Math.floor(100000 + Math.random() * 900000);
        //console.log(generatedCode);
        var template_params = {
            username: username,
            from_name: 'Boutique do Suspiro',
            to_name: username,
            subject: 'Confirmação de sua inscrição nas Novidades da Boutique do Suspiro.',
            subject1: 'Confirmation of your subscription to Boutique do Suspiro News.',
            email: email,
            message: 'Favor digitar o código abaixo na janela de inscrição para confirmar seu email.',
            message1: 'Please enter the code below in the registration window to confirm your email address.',
            code: generatedCode,
        }

        var service_id = "contact_service";
        var template_id = "template_s9ajvnk";
        var user_id = 'G6jYL3-Jxy_YNy4Xz'; //'2FZ9hkxvRt-6Y4sVG'; //user_7kPkOhEo0c6N7DHtmJ9o7'


        if ((username === '') || (email === '')) {
            document.getElementById('message-error').innerHTML = i18n.t(k.EMAIL_ERROR);
        } else {
            emailjs.send(service_id, template_id, template_params, user_id)
                .then((result) => {
                    //console.log(result.text);
                    document.getElementById('message-error').innerHTML = i18n.t(k.EMAIL_ENVIADO);
                    setConfirmationCode(generatedCode);
                    setConfirmationSent(true);
                    // resetForm();
                    // setTimeout(() => {
                    //     document.getElementById('message-error').innerHTML = "";
                    // }, 5000);
                }, (error) => {
                    console.log(error.text);
                });
        }

        // Oculta o pop-up
        //setShowPopup(false);
    };


    const handleClose = () => {
        // Oculta o pop-up sem se inscrever
        setShowPopup(false);
    };

  return (
    <>
      <Box
          sx={{
              display: 'flex',
              flexWrap: 'wrap',
              '& > :not(style)': {
                  display: 'block',
                  marginTop: '78px',
                  // position: 'absolute',
                  // top: '5.5rem',
                  maxWidth: '100%',
                  width: '100%',
                  height: 80,
              },
          }}
      >
          <Paper elevation={0} sx={{ display: 'flex', textAlign: 'center' }} className="paper-back">
              <Typography className="title" variant="h4">{i18n.t(k.BOUTIQUE)}</Typography>
              <Typography className="subtitle" variant="h5">{i18n.t(k.NOVIDADES)}</Typography>
          </Paper>

      </Box>
      <Box sx={{display: 'block', textAlign: 'center', margin: '20px'}}>
        <img className="news-logo" src={logo} alt="logo" />
        <img className="news-logo" src={logo_puro} alt="logo" />
     </Box>
     <Box>
          {showPopup && (
              <div className="newsletter-popup">
                  <div className="popup-content">
                          <ModalMessage
                            open={showPopup}
                              handleClose={handleClose}
                              handleSubmit={handleSubscribe}
                              confirmationSent={confirmationSent}
                              confirmationCode={confirmationCode}
                          />
                  </div>
              </div>
          )}
        </Box>
        {/* <Box>
            <div id="message-error">

            </div>
        </Box> */}
      <FooterContainer className="footer--pin" />
      </>
  )
}
