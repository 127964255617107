import React, { useState } from 'react';
// import localforage from 'localforage';
//import styles from './page.module.css';
import { makeStyles } from "@material-ui/core";
import { styled } from '@mui/material/styles';
//import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
//import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import {  purple } from '@mui/material/colors';

import i18n from 'i18next';
import k from "./../i18n/keys";

import logo from './../assets/images/logo_puro.png';
//import * as generalService from '../services/generalService';

const initialValues = {
    username: '',
    email: '',
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const useStyles = makeStyles(theme => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    appMain: {
        paddingLeft: '250px',
        width: '100%'
    },
    root: {
        backgroundColor: '#fafafa',
    },
    login_buttons: {
        display: 'block',
        margin: '5px 0  0 0',
        textAlign: 'center',
    },
    login_btn: {
        display: 'inlineBlock',
        margin: '0 10px 10px 10px!important',
    },
    login_field: {
        display: 'flex',
        margin: '10px auto',
        marginTop: '40px',
        width: '100%',
    },
    login_header: {
        textAlign: 'center',
        color: '#ad8448',
        fontSize: '2rem',
    },
    login_message: {
        textAlign: 'center',
        color: '#80a8d0',
        fontSize: '1.5rem',
        paddingTop: '1rem',
    },
    modal: {
        '&.css-1wnsr1i': {
            maxWidth: 645,
            position: 'absolute',
            top: '50%',
            margin: '0 auto',
            width: '400px',
        }
    },
    newwsTitle: {
        color: '#6a219a',
        fontSize: '1.5rem',
        textAlign: 'center',
        paddingTop: '0.5rem',
    },
    newwsQuestion: {
        color: '#6a219a',
        fontSize: '1rem',
        textAlign: 'center',
        paddingTop: '0.5rem',
    },
    register_field: {
        color: 'ac9551',
    }
}))

export default function ModalMessage(props) {

    const classes = useStyles();
    const validateOnChange = true;



    // eslint-disable-next-line
    const { open, handleClose, handleSubmit, confirmationSent, confirmationCode } = props;
    const [ enteredCode, setEnteredCode ] = useState('');
    const [ erro, setErro ] = useState(false);

    const [values, setValues] = useState(initialValues);
    // eslint-disable-next-line
    const [errors, setErrors] = useState({});

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText(purple[700]),
        backgroundColor: purple[700],
        '&:hover': {
            backgroundColor: purple[100],
        },
    }));

    const ColorButtonBG = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#ac9551'),
        backgroundColor: '#ac9551',
        '&:hover': {
            backgroundColor: '#dccea5',
        },
    }));

    const handleInputChange = (event) => {
        //console.log('Input Change', event.target.name, event.target.value);
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
        if (validateOnChange) {
            //console.log('Validation', { [name]: value })
            validate({ [name]: value })
        }
    }

    const validate = (fieldValues = values) => {
        //console.log('Values', fieldValues)
        let temp = { ...errors };
        //console.log('Temp', temp)
        if ('email' in fieldValues)
            temp.email = fieldValues.email ? "" : "Preenchimento necessário!";
        if ('username' in fieldValues)
            temp.username = fieldValues.username ? "" : "Preenchimento necessário!";

        // setErrors({ ...temp });
        // console.log('Errors', errors, 'Temp', temp)

        if (fieldValues === values) // Identify validation from submit
            return Object.values(temp).every(x => x === "")
    }

    const handleConfirmCode = () => {
        //console.log('Confirmation Code', confirmationCode, 'Entered Code',enteredCode)
        // Check if the entered code matches the generated confirmation code
        if (parseInt(confirmationCode) === parseInt(enteredCode)) {
            //console.log('Confirmation successful!'); // You may proceed with further actions
            setErro(false);
            handleClose();
        } else {
            //console.log('Confirmation code does not match.');
            setErro(true);
        }
    };



    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className={classes.modal}>
                <Card sx={{ maxWidth: 245, mx: 'auto' }}>
                    <CardMedia
                        sx={{ height: 300 }}
                        image={logo} alt="Customer Logo"
                        title="Card Media"
                    />
                </Card>

                <div className="newsTitle">{i18n.t(k.BOUTIQUE)} </div>
                <div className="newsQuestion">{i18n.t(k.BOUTIQUE_NEWSLETTER)} </div>

                <Box>
                <TextField size="small"
                    sx={{ input: { color: purple[300] }, label: { color: purple[900] }, mb: 1.5 }}
                    className={classes.register_field}
                    id="email"
                    name="email"
                    label={i18n.t(k.EMAIL)}
                    type="text"
                    value={values.email}
                    onChange={handleInputChange}
                    // error={errors.email}
                    autoComplete="new-password"
                    helperText={errors && errors.email && "Preenchimento Necessário!"}
                />

                <TextField size='small'
                    sx={{ input: { color: purple[300] }, label: { color: purple[900] }, mb: 1.5 }}
                    className={classes.register_field}
                    id="username"
                    name="username"
                    label={i18n.t(k.USERNAME)}
                    type="text"
                    value={values.username}
                    onChange={handleInputChange}
                    autoComplete="new-password"
                    helperText={errors && errors.username && "Preenchimento Necessário!"}
                />
                </Box>

                {!confirmationSent ? (
                    <>
                    <Box className={classes.login_buttons}>
                        <ColorButton variant="contained" className={classes.login_btn} onClick={(event) => handleSubmit(event, values.email, values.username)}>{"Enviar Email de Confirmação"}</ColorButton>
                        <ColorButtonBG variant="contained" className={classes.login_btn} onClick={(event) => handleClose(event, 'logout')}>{i18n.t(k.CANCEL)}</ColorButtonBG>
                    </Box>
                    {/* <button onClick={handleSendConfirmation}>Send Confirmation Email</button> */}
                    </>
                ) : (
                    <>
                        <TextField size='small'
                            sx={{ input: { color: purple[300] }, label: { color: purple[900] }, mb: 1.5 }}
                            className={classes.register_field}
                            id="confirm"
                            name="username"
                            label={i18n.t(k.CONFIRMATION_CODE)}
                            type="text"
                            value={enteredCode}
                            onChange={(e) => setEnteredCode(e.target.value)}
                            error={erro}
                            autoComplete="new-password"
                            helperText= {erro === false ? i18n.t(k.CONFIRMATION) : i18n.t(k.PREENCHER)}
                        />

                        <br />
                            <ColorButton variant="contained" className={classes.login_btn} onClick={handleConfirmCode}>{i18n.t(k.SIGNUP)}</ColorButton>
                            <ColorButtonBG variant="contained" className={classes.login_btn} onClick={(event) => handleClose(event, 'logout')}>{i18n.t(k.CANCEL)}</ColorButtonBG>
                    </>
                )}
                <Box>
                    <div id="message-error">


                    </div>
                </Box>
            </Box>
        </Modal>

    )
}