import React, { useState } from 'react';
import posthog from 'posthog-js';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import i18n from 'i18next';
import k from "./../i18n/keys";

export default function CookieBanner(props) {


    //console.log('Cookie Props', props)
    const [ showBanner, setShowBanner ] = useState(true);

    const acceptCookies = () => {
        posthog.opt_in_capturing();
        setShowBanner(false);
    }

    const declineCookies = () => {
        posthog.opt_out_capturing();
        setShowBanner(false);
    }

    return (

        (showBanner ?
            <Card sx={{ maxWidth: 445, border: '1px black solid', background: '#dcc4ec', zIndex: 1 }} className="cookie-banner" raised={true} >
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                            {i18n.t(k.COOKIE)}
                    </Typography>
                    <Typography variant="body3" color="text.secondary">
                            {i18n.t(k.COOKIES)}
                    </Typography>
                </CardContent>
                <CardActions>
                        <Button sx={{ mx: 'auto', color: '#6a219a' }} size="large" onClick={acceptCookies}>{i18n.t(k.ACCEPT)}</Button>
                        <Button sx={{ mx: 'auto', color: '#6a219a' }} size="large" onClick={declineCookies}>{i18n.t(k.DECLINE)}</Button>
                </CardActions>
            </Card>

        :
            null
        )
    )
}
