import React, {useState, useEffect } from 'react';
import i18n from 'i18next';
//import { Trans } from 'react-i18next';
import k from "../i18n/keys";
import ReactGA from 'react-ga4';
import { makeStyles } from "@material-ui/core";
import { Paper, Typography, Box, Grid } from '@mui/material';
import Card from '@mui/material/Card';
//import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { FooterContainer } from '../containers/footer';
import img4 from './../assets/images/bds4.png';

import evento1 from './../assets/images/carousel4.png'
import evento2 from './../assets/images/bds4.png'
import evento3 from './../assets/images/carousel6.png'

const useStyles = makeStyles(theme => ({
    appMain: {
        // paddingLeft: '250px',
        // width: '100%'
    },
    root: {
        backgroundColor: '#fafafa',
    },
    btn: {
        padding: theme.spacing(1)
    },
    menu: {
        display: 'flex',
        top: '60px',
        position: 'fixed',
        width: '80%',
        zIndex: 2,
        backgroundColor: '#fafafa',
    },
    splash: {
        width: '60%',
        height: 'auto',
        // position: 'absolute',
        // top: '13rem',
        // left: '20%',
        opacity: '0.7',
        margin: '20px auto 0 auto',
        '@media(max-width: 700px)': {
            width: '90%',
            left: '5%',
        }
    },
    splashImage: {
        width: '60%',
        height: 'auto',
        // position: 'absolute',
        // top: '13rem',
        // left: '20%',
        opacity: '0.7',
        maxWidth: '100%',
        '@media(max-width: 700px)': {
            width: '90%',
            left: '5%',
        }
    },
    headline: {
        textAlign: 'center',
        padding: '5px',
        // marginTop: '47vw',
        fontSize: '2rem',
        width: '100%',
        '@media(max-width: 820px)': {
            fontSize: '1.8rem',
            // marginTop: '60vw',
        },
        '@media(max-width: 420px)': {
            fontSize: '1.5rem',
            // marginTop: '105vw',
        },
    },
    headline1: {
        color: 'ç',
        fontSize: '30px',
        position: 'absolute',
        left: '6%',
        top: '14rem',
        '@media(max-width: 700px)': {
            // fontSize: '30px',
            // top: '8rem',
            // left: '3%',
            color: '#fafafa',
        }
    },
    imgFluid: {
        maxWidth: '100%',
        height: 'auto',
    },
    cardItem: {
        background: '#6a219a',
        // 'linear-gradient(-45deg, #6a219a, white, white, #6a219a)',
        textAlign: 'center',
        color: '#ac9551',
        fontWeight: 'bold',
    },
    cardContent: {
        color: '#6a219a',
        margin: '0px',
        fontSize: '1.05rem',
    },
    cardButton: {
        color: '#ac9551',
        fontWeight: 'bold',
        display: 'block',
        margin: '0 auto!important',
        textDecoration: 'none',
    },
    paperBack: {
        marginTop: '20px',
        background: 'red',
    },
}))




export default function Eventos() {

    const classes = useStyles();
    const [ items ] = useState(3)
    const eventos = [
        evento1,
        evento2,
        evento3,
    ];
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/eventos", title: "Boutique Eventos" });
        ReactGA.event({
            category: "Boutique Eventos",
            action: "User Access to Eventos",
        });
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        display: 'block',
                        margin: '78px 0 20px auto',
                        // marginBottom: '20px',
                        // position: 'absolute',
                        // top: '5.5rem',
                        maxWidth: '100%',
                        width: '100%',
                        height: 80,
                    },
                }}
            >
                <Paper elevation={12} sx={{ display: 'flex', textAlign: 'center' }} className="paper-back">
                    <Typography className="title" variant="h4">{i18n.t(k.BOUTIQUE)}</Typography>
                    <Typography className="subtitle" variant="h5" sx={{ fontSize: { 'sm': '1.2rem', 'md': '1.5' } }}>{i18n.t(k.EVENTOS_HEAD)}</Typography>
                </Paper>
            </Box>

            <Card className="home-card" sx={{ maxWidth: 1400, height: { xs: 'auto', xl: 'auto' }, boxShadow: 3, background: '#eee2f5' }}>
                <Typography
                    className={classes.cardItem}
                    gutterBottom
                    variant="h5"
                    component="div"
                >
                    {i18n.t(k.EVENTOS)}
                </Typography>
                <CardMedia
                    sx={{ height: 400, width: '50%', display: 'block', margin: '0 auto' }}
                    image={img4}
                    title="EVENTOS"
                />

                <CardContent>
                    <Typography sx={{ display: 'block', fontSize: '1.3rem' }}
                        variant="body2"
                        className={classes.cardContent}>
                        {i18n.t(k.EVENTOS_CARD)}
                    </Typography>
                </CardContent>
                {/* <CardActions>
                        <a href="#/oem" size="small" className={classes.cardButton}>{i18n.t(k.LEARN_MORE)}</a>
                    </CardActions> */}
            </Card>

            <Paper style={{ height: 'auto', padding: '16px', marginTop: '30px', background: '#eee2f5' }}>
                <Grid container spacing={4} >
                    {Array.from(Array(items), (e, i) => {

                        return <Grid key={i} item xs={12} sm={8} md={4} sx={{ height: 'auto' }}>

                            <Card key={i} className="home-card" sx={{ background: '#fdedeb', maxWidth: 500, height: { xs: 'auto', xl: '450px' }, boxShadow: 3, marginLeft: { md: '10px' } }}>
                                <Typography
                                    className={classes.cardItem}
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                >
                                    {i18n.t(k.EVENTOS_MENU)} {`${i+1}`}
                                </Typography>
                                <CardMedia
                                    className={classes.imgFluid}
                                    sx={{
                                        width: '600px',
                                        height: '300px',
                                        display: 'block',
                                        margin: '0 auto',
                                        opacity: '1',
                                    }}
                                    image={eventos[i]}

                                    title="Evento"
                                />
                            </Card>
                        </Grid>
                    })}
                </Grid>
            </Paper>

            <FooterContainer className="footer--pin" />
        </React.Fragment>
    );
};
