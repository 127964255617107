
import './App.css';
import './Kai.css';
import React, { useEffect, useState }  from 'react';
//import localforage from 'localforage';
import ReactGA from 'react-ga4';
import { createBrowserHistory } from 'history';
// import * as localforage from 'localforage';
//import { createTheme } from '@mui/material/styles';
// import { makeStyles } from "@material-ui/core";

//import * as generalService from '../services/generalService';
import Header from '../components/Header';

//import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import CookieBanner from '../components/CookieBanner';
import posthog from 'posthog-js';
// import { QueryClient, QueryClientProvider } from "react-query";


function App(props) {

// eslint-disable-next-line
  const [ lng, setLng ] = useState('pt-BR');
  //sessionStorage.setItem('lng', 'pt-BR')

  // const client = new QueryClient({defaultOptions: {
  //   queries: {
  //     refetchOnWindowFocus: true,
  //   }
  // }});

  useEffect(() => {
    //console.log('Language', sessionStorage.getItem('lng'))
    if (!sessionStorage.getItem('lng'))  {
    sessionStorage.setItem('lng', 'pt-BR');
    setLng('pt-BR')
    }else {
      // if ((sessionStorage.getItem('lng') !== 'pt-BR' && sessionStorage.getItem('lng') !== 'en' )) {
      //   sessionStorage.setItem('lng', 'pt-BR');
      // }
    }
  }, [])

  // useEffect(() => {
  //   localforage.config({
  //     driver: localforage.WEBSQL, // Force WebSQL; same as using setDriver()
  //     name: 'boutiqueClients',
  //     version: 1.0,
  //     size: 4980736, // Size of database, in bytes. WebSQL-only for now.
  //     storeName: 'keyvaluepairs', // Should be alphanumeric, with underscores.
  //     description: 'Boutique do Suspirp Local Forage'
  //   });
  // },[])


  const changeLanguage = (lng) => {
    console.log('Language =', lng)
    sessionStorage.setItem('lng', lng);
    setLng(lng);
    i18n.changeLanguage(lng);
    window.location.reload(true);
  };

  const trackingId = "AW-947621178"; //"G-278H0FL87D"; // Replace with your Google Analytics tracking ID
  ReactGA.initialize(trackingId);
  ReactGA.set({
    userId: 'ascalmon',
    // any data that is relevant to the user session
    // that you would like to track with google analytics
  })

  const history = createBrowserHistory();
  // Initialize google analytics page view tracking
  history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: 'Boutique App'}); // Record a pageview for the given page
  });

  return (
    <React.Fragment>
      {/* <QueryClientProvider client={client}> */}
      <Header
        changeLanguage={changeLanguage}
      />
      {/* </QueryClientProvider> */}
      {posthog.has_opted_in_capturing() || posthog.has_opted_out_capturing() ? null : <CookieBanner />}
      {/* <CookieBanner /> */}

    </React.Fragment>
  )
}

export default App;
