import React, { useState } from 'react'
import { HashRouter, Link, Route, Routes, Navigate } from "react-router-dom";
// import Axios from 'axios';
// import { useQuery } from 'react-query';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from "@material-ui/core";
import { IconButton, Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import MoreIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { purple } from '@mui/material/colors';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import ContactsIcon from '@mui/icons-material/Contacts';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import CircularProgress from '@mui/material/CircularProgress';
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {
    IconFlagBR,
    IconFlagUS
} from 'material-ui-flags';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { FaWhatsapp } from 'react-icons/fa';

import i18n from 'i18next';
import k from "./../i18n/keys";

import About from '../components/About';
import Home from '../components/Home';
import Contact from '../components/Contact';
import News from '../components/News';
import Formas from '../components/FormasEntrega';
import NotFound from '../components/NotFound';
// import { FooterContainer } from '../containers/footer';

//import * as generalService from '../services/generalService';
import logo from './../assets/images/logo.png';
import Lembrancinhas from './Lembrancinhas';
import Pavlovas from './Pavlovas';
import Personalizados from './Personalizados';
import Eventos from './Eventos';
import Cards from './Cards';
import Avaliacoes from './Avaliacoes';
import Portfolio from './Portfolio';
import MessageGrid from './MessageGrid';
// withStyles & makeStyles


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fff !important',
        transform: 'translateZ(0)',
        //position: 'static',

    },
    searchInput: {
        opacity: '0.6',
        padding: '0px 8px',
        fontSize: '0.8rem',
        marginLeft: '1.5rem',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: '#f2f2f2'
        },
        '& .MuiSvgIcon-root': { // If there is an icon (copied from the search icon), please apply margin 8px
            marginRight: theme.spacing(1)   // spacing(1) = '8px'
        }
    },
    logo: {
        display: 'block',
        position: 'relative',
        // width: '100%',
        maxWidth: '4.5rem',
        margin: '3px auto 3px auto',
        background: '#dcc4ec'
    },
    logo_a: {
        width: 'auto',
    },
    drawer: {
        width: 150,
        background: '#fafafa',

        '@media(max-width: 700px)': {
            width: 100,
        }
    },
    drawerItem: {
        // fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif!important',
        fontSize: '0.8rem !important',
        fontWeight: 600,
        color: '#c3a67c',
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: '#6a219a',
        '&:hover': {
            color: '#6a219a'
        },
        // textTransform: 'lowercase',
    },
    linkDropdown: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        '&:hover': {
            color: '#dcc4ec',
            fontSize: '1.5rem',
        },

    },
    MuiMenuRoot:{
        background: 'red',
    }
}))


export default function Header(props) {

    const { changeLanguage } = props;
    // const [ state, setState ] = useState('');

    // const options = {
    //     method: 'GET',
    //     url: 'https://open-weather13.p.rapidapi.com/city/Osasco',
    //     headers: {
    //         'X-RapidAPI-Key': '1f2bdacb33mshc6f3872e6cd2886p1f82b8jsn67b5f72b2d43',
    //         'X-RapidAPI-Host': 'open-weather13.p.rapidapi.com'
    //     }
    // };
    // const { data: weatherData, isLoading, isError, refetch } = useQuery(["weather"], () => {
    //     return Axios.request(options).then((res) => res.data);
    // });

// useEffect(() => {
//     if (!isLoading) {
//         //console.log('Data', ((parseInt(weatherData.main.temp) - 32) * 5 / 9).toFixed(2), 'isError', isError, 'Props', props)
//         setState(weatherData);
//     }
//     // eslint-disable-next-line
// }, [ weatherData ])


    const classes = useStyles();
    // console.log('Props', props)
    //const menuColors = generalService.getMenuIcons();
    // eslint-disable-next-line
    const [ display, setDisplay ] = useState('none');
    // const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    // const [ showRegisterForm, setShowRegisterForm ] = useState(false);


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // const handleMenuItemClick = (item) => {
    //     console.log(`Clicked on ${item}`);
    //     // You can add additional logic for handling the selected item
    //     handleClose();
    // };



    const callWhatsapp = () => {
        const phoneNumber = '5511990052502';
        // Construct the WhatsApp URL with the phone number
        const whatsappUrl = `https://wa.me/${phoneNumber}`;
        window.open(whatsappUrl, '_blank');
    }

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };


    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };


    // const handleMenuClose = (name, event) => {
    //     console.log('MenuClose', name);
    //     setAnchorEl(null);
    //     handleMobileMenuClose();
    // };

    // const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const menuId = 'primary-search-account-menu';
    // const renderMenu = (
    //     <Menu
    //         anchorEl={anchorEl}
    //         anchorOrigin={{
    //             vertical: 'top',
    //             horizontal: 'right',
    //         }}
    //         id={menuId}
    //         keepMounted
    //         transformOrigin={{
    //             vertical: 'top',
    //             horizontal: 'right',
    //         }}
    //         open={isMenuOpen}
    //         onClose={handleMenuClose}
    //     >
    //         <MenuItem onClick={(event) => handleMenuClose('login', event)} name="login">{i18n.t(k.LOG)}</MenuItem>
    //         {/* <MenuItem onClick={(event) => handleMenuClose('register', event)} name="register">Registro</MenuItem> */}
    //         <MenuItem onClick={(event) => handleMenuClose('logout', event)} name="logout">{i18n.t(k.EXIT)}</MenuItem>
    //     </Menu>
    // );


    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >

        <Box sx={{ flexGrow: 1, background: '#dcc4ec' }}>
            <MenuItem onClick={() => changeLanguage('en')}>
                <Tooltip title={i18n.t(k.COUNTRY_TOOL)}>
                <IconButton
                    size="small"
                    edge="end"
                    aria-label="US Flag"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    color="inherit"
                >
                    <IconFlagUS />
                </IconButton>
                </Tooltip>
            </MenuItem>

            <MenuItem onClick={() => changeLanguage('pt-BR')}>
                <Tooltip title={i18n.t(k.COUNTRY1_TOOL)}>
                <IconButton
                    size="small"
                    edge="end"
                    aria-label="BR Flag"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    color="inherit"
                >
                    <IconFlagBR />
                </IconButton>
                </Tooltip>
            </MenuItem>

                <MenuItem onClick={handleMobileMenuClose}>
                <Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                    <Link to="/about" className={classes.link}>
                        <IconButton
                            size="small"
                            edge="end"
                            aria-label="About us"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            color="inherit"
                        >
                        <InfoIcon sx={{ color: '#6a219a', ml: 0 }} />
                        </IconButton>
                        <Typography
                            variant="body2"
                            noWrap
                            component="div"
                            sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#6a219a', ml: 0 }}
                        >
                            {i18n.t(k.ABOUT)}
                        </Typography>
                    </Link>
                </Box>
            </MenuItem>

            <MenuItem onClick={handleMobileMenuClose}>
                <Link to="/contact" className={classes.link}>
                    <Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                        <IconButton
                            size="small"
                            edge="end"
                            aria-label="Fleet"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <ContactsIcon sx={{ color: '#6a219a', ml: 0 }} />
                        </IconButton>
                        <Typography
                            variant="body2"
                            noWrap
                            component="div"
                            sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#6a219a', ml: 0 }}
                        >
                            {i18n.t(k.CONTACT)}
                        </Typography>
                    </Box>
                </Link>
            </MenuItem>

            <MenuItem onClick={handleMobileMenuClose}>
                <Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                    <Link to="/news" className={classes.link}>
                        <IconButton
                            size="small"
                            edge="end"
                            aria-label="Contact us"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <EmojiObjectsIcon sx={{ color: '#6a219a', ml: 0 }} />

                        </IconButton>
                        <Typography
                            variant="body2"
                            noWrap
                            component="div"
                            sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#6a219a', ml: 0 }}
                        >
                            {i18n.t(k.NEWS)}
                        </Typography>
                    </Link>
                </Box>
            </MenuItem>

            <MenuItem onClick={handleMobileMenuClose}>
                <Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                    <Link to="/lembrancinhas" className={classes.link}>

                        <Typography
                            variant="body2"
                            noWrap
                            component="div"
                            sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#6a219a', ml: 0 }}
                        >
                            {i18n.t(k.LEMBRANCINHA_MENU)}
                        </Typography>
                    </Link>
                </Box>
            </MenuItem>
            <MenuItem onClick={handleMobileMenuClose}>
                <Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                    <Link to="/pavlovas" className={classes.link}>

                        <Typography
                            variant="body2"
                            noWrap
                            component="div"
                            sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#6a219a', ml: 0 }}
                        >
                            {i18n.t(k.PAVLOVAS_MENU)}
                        </Typography>
                    </Link>
                </Box>
            </MenuItem>
            <MenuItem onClick={handleMobileMenuClose}>
                <Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                    <Link to="/personalizados" className={classes.link}>

                        <Typography
                            variant="body2"
                            noWrap
                            component="div"
                            sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#6a219a', ml: 0 }}
                        >
                            {i18n.t(k.PERSONALIZADOS_MENU)}
                        </Typography>
                    </Link>
                </Box>
            </MenuItem>
            <MenuItem onClick={handleMobileMenuClose}>
                <Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                    <Link to="/eventos" className={classes.link}>

                        <Typography
                            variant="body2"
                            noWrap
                            component="div"
                            sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#6a219a', ml: 0 }}
                        >
                            {i18n.t(k.EVENTOS_MENU)}
                        </Typography>
                    </Link>
                </Box>
            </MenuItem>
            <MenuItem onClick={handleMobileMenuClose}>
                <Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                    <Link to="/cards" className={classes.link}>

                        <Typography
                            variant="body2"
                            noWrap
                            component="div"
                            sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#6a219a', ml: 0 }}
                        >
                            {i18n.t(k.ESPECIALIDADES)}
                        </Typography>
                    </Link>
                </Box>
            </MenuItem>
            <hr />
            <MenuItem onClick={handleMobileMenuClose}>
                <Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                    <Link to="/portfolio" className={classes.link}>

                        <Typography
                            variant="body2"
                            noWrap
                            component="div"
                            sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#6a219a', ml: 0 }}
                        >
                            {i18n.t(k.PORTFOLIO_TITULO)}
                        </Typography>
                    </Link>
                </Box>
            </MenuItem>
            <MenuItem onClick={handleMobileMenuClose}>
                <Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                    <Link to="/numbers" className={classes.link}>

                        <Typography
                            variant="body2"
                            noWrap
                            component="div"
                            sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#6a219a', ml: 0 }}
                        >
                            {i18n.t(k.NUMBER)}
                        </Typography>
                    </Link>
                </Box>
            </MenuItem>
            <MenuItem onClick={handleMobileMenuClose}>
                <Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                    <Link to="/avaliacoes" className={classes.link}>

                        <Typography
                            variant="body2"
                            noWrap
                            component="div"
                            sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#6a219a', ml: 0 }}
                        >
                            {i18n.t(k.AVALIACOES_TITULO)}
                        </Typography>
                    </Link>
                </Box>
            </MenuItem>
            <MenuItem onClick={handleMobileMenuClose}>
                <Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                    <Link to="/formas" className={classes.link}>

                        <Typography
                            variant="body2"
                            noWrap
                            component="div"
                            sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#6a219a', ml: 0 }}
                        >
                            {i18n.t(k.FORMAS_MENU)}
                        </Typography>
                    </Link>
                </Box>
            </MenuItem>
            </Box>
        </Menu>
    );


    return (
        <HashRouter>
        <>
        {/* {!isLoading ? */}
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" className={ classes.root}>
                <Toolbar sx={{ background: purple[800] }} >

                    <Link to="/">
                    <Tooltip title={i18n.t(k.HOME_TOOL)} >
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ display: { xs: 'block', sm: 'block' }, mr: '3rem' }}
                    >
                    <img className={classes.logo} src={logo} alt="Boutique Logo" />
                    </Typography>
                    </Tooltip>
                    </Link>

                    {/* <span>{((parseInt(weatherData?.main.temp) - 32) * 5 / 9 ).toFixed(2)} </span> */}
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, flex: 'auto'}} className="navbar-grid">
                         <MenuItem>
                            <Box  sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }} >
                            <Link to="/home" className={classes.link}>
                            <IconButton
                                size="small"
                                edge="end"
                                aria-label="About us"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                color="inherit"
                            >
                                <HomeIcon sx={{ color: '#ac9551', ml: 0 }} />
                            </IconButton>
                            <Typography
                                className="menu-hover"
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ display: { xs: 'flex', sm: 'block' }, color: '#ac9551', ml: 0, textDecoration: 'none' }}
                            >
                                {i18n.t(k.HOME)}
                            </Typography>
                            </Link>
                            </Box>
                        </MenuItem>

                        <MenuItem>
                            <Box  sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }} >
                            <Link to="/about" className={classes.link} id="about-link">
                            <IconButton
                                size="small"
                                edge="end"
                                aria-label="About us"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                color="inherit"
                            >
                            <InfoIcon sx={{ color: '#ac9551', ml: 0 }} />
                            </IconButton>
                            <Typography
                                className="menu-hover"
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ display: { xs: 'flex', sm: 'block' }, color: '#ac9551', ml: 0, textDecoration: 'none' }}
                            >
                                {i18n.t(k.ABOUT)}
                            </Typography>
                            </Link>
                            </Box>
                        </MenuItem>

                        <MenuItem>
                            <   Box sx={{ display: { xs: 'inline-flex', md: 'flex' }, alignItems: 'center' }}>
                                <Link to="/contact" className={classes.link} id="contact-link">
                                    <IconButton
                                        size="small"
                                        edge="end"
                                        aria-label="Fleet"
                                        aria-controls={menuId}
                                        aria-haspopup="true"
                                        color="inherit"
                                    >
                                                <ContactsIcon sx={{ color: '#ac9551', ml: 0 }} />
                                    </IconButton>
                                    <Typography
                                        className="menu-hover"
                                        variant="h6"
                                        noWrap
                                        component="div"
                                        sx={{ display: { xs: 'inline-flex', sm: 'block' }, color: '#ac9551', ml: 0 }}
                                    >
                                        {i18n.t(k.CONTACT)}
                                    </Typography>
                                </Link>
                                </Box>
                        </MenuItem>

                        <MenuItem>
                            <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                            <Link to="/news" className={classes.link} id="news-link">
                            <IconButton
                            size="small"
                            edge="end"
                            aria-label="Contact us"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            color="inherit"
                        >
                        <EmojiObjectsIcon sx={{ color: '#ac9551', ml: 0 }} />

                        </IconButton>
                            <Typography
                                className="menu-hover"
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ display: { xs: 'none', sm: 'block' }, color: '#ac9551' }}
                            >

                                {i18n.t(k.NEWS)}
                            </Typography>
                            </Link>
                            </Box>
                        </MenuItem>

                        <MenuItem>
                            <Box>

                                <Button
                                    sx={{ color: '#ac9551', display: 'flex' }}
                                    id="fade-button"
                                    aria-controls={open ? 'fade-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                <IconButton
                                    size="small"
                                    edge="end"
                                    aria-label="Contact us"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    color="inherit"
                                >
                                    <ManageAccountsIcon sx={{ color: '#ac9551', ml: 0 }} />
                                </IconButton>
                                <Tooltip title={i18n.t(k.OPCOES_TOOL)} >

                                <Typography
                                    className={classes.linkDropdown}
                                    variant="h6"
                                    noWrap
                                    component="div"
                                    sx={{ display: { xs: 'flex', sm: 'block' }, color: '#ac9551', ml: 0, textDecoration: 'none', textTransform: 'none' }}
                                >
                                    {i18n.t(k.OPCOES)}
                                </Typography>
                                </Tooltip>
                                </Button>

                                    <Menu
                                    id="fade-menu"
                                    // aria-labelledby="fade-button"
                                    MenuListProps={{
                                        'aria-labelledby': 'fade-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    TransitionComponent={Fade}
                                >

                                    <MenuItem onClick={handleClose} sx={{ background: '#dcc4ec' }}>
                                        <Link to="/lembrancinhas" className={classes.link}>{i18n.t(k.LEMBRANCINHA_MENU)}</Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleClose} sx={{ background: '#dcc4ec' }}>
                                        <Link to="/pavlovas" className={classes.link}>{i18n.t(k.PAVLOVAS_MENU)}</Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleClose} sx={{ background: '#dcc4ec' }}>
                                        <Link to="/personalizados" className={classes.link}>{i18n.t(k.PERSONALIZADOS_MENU)}</Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleClose} sx={{ background: '#dcc4ec' }}>
                                        <Link to="/eventos" className={classes.link}>{i18n.t(k.EVENTOS_MENU)}</Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleClose} sx={{ background: '#dcc4ec' }}>
                                        <Link to="/cards" className={classes.link}>{i18n.t(k.ESPECIALIDADES)}</Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleClose} sx={{ background: '#dcc4ec' }}>
                                        <Link to="/portfolio" className={classes.link}>{i18n.t(k.PORTFOLIO_TITULO)}</Link>
                                    </MenuItem>
                                    <hr />
                                    <MenuItem onClick={handleClose} sx={{ background: '#dcc4ec' }}>
                                        <Link to="/numbers" className={classes.link}>{i18n.t(k.NUMBER)}</Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleClose} sx={{ background: '#dcc4ec' }}>
                                        <Link to="/avaliacoes" className={classes.link}>{i18n.t(k.AVALIACOES_TITULO)}</Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleClose} sx={{ background: '#dcc4ec' }}>
                                        <Link to="/formas" className={classes.link}>{i18n.t(k.FORMAS_MENU)}</Link>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </MenuItem>

                    </Box>


                    <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ display: { xs: 'none', md: 'flex' , marginRight: '1rem'} }}>
                            <Tooltip title={i18n.t(k.WHATS_TOOL)}>
                            <IconButton
                                size="small"
                                edge="end"
                                aria-label="Whatsapp"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={callWhatsapp}
                                color="inherit"
                            >
                            <FaWhatsapp />
                            </IconButton>
                            </Tooltip>
                        </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Tooltip title={i18n.t(k.COUNTRY_TOOL)}>
                        <IconButton
                            size="small"
                            edge="end"
                            aria-label="US Flag"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={() => changeLanguage('en')}
                            color="inherit"
                        >
                        <IconFlagUS  />
                        </IconButton>
                        </Tooltip>
                        <Tooltip title={i18n.t(k.COUNTRY1_TOOL)}>
                        <IconButton
                            size="small"
                            edge="end"
                            aria-label="BR Flag"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={() => changeLanguage('pt-BR')}
                            color="inherit"
                        >
                            <IconFlagBR />
                        </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                        <MoreIcon sx={{ color: '#ac9551' }} />
                        </IconButton>
                    </Box>

                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {/* {renderMenu} */}
        </Box>
        {/* :
        <span>{ "Loading" }</span>
        } */}
        <Routes>

            <Route path="/" element={<Home />} />
            <Route path="home" element={<Home />} />
            {/* <Route path="/:lng" element={<Home changeLanguage={changeLanguage}/>} /> */}
            <Route path="about" element={<About />} />
            <Route path="news" element={<News />} />
            <Route path="contact" element={<Contact />} />
            <Route path="formas" element={<Formas />} />
            <Route path="lembrancinhas" element={<Lembrancinhas />} />
            <Route path="pavlovas" element={<Pavlovas />} />
            <Route path="personalizados" element={<Personalizados />} />
            <Route path="eventos" element={<Eventos />} />
            <Route path="numbers" element={<MessageGrid />} />
            <Route path="cards" element={<Cards />} />
            <Route path="avaliacoes" element={<Avaliacoes />} />
            <Route path="portfolio" element={<Portfolio />} />
            <Route path="*" exact={true}  element={<Navigate to="/404" replace />} />
            <Route path="/404"  element={<NotFound />} />


        </Routes>
        </>

            <Box sx={{
                display: { display }, position: 'absolute', top: '50%', left: '50%'
            }}>
                <CircularProgress sx={{ color: '#51708e', width: '60px!important', height: '60px!important', zIndex: '5' }} />
            </Box>
        </HashRouter>
    );
}
