import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
//import { Trans } from 'react-i18next';
import k from "../i18n/keys";
import { Tooltip, makeStyles } from "@material-ui/core";
import { Paper, Grid, Typography } from '@mui/material';
import { purple } from '@mui/material/colors';
import Box from '@mui/material/Box';
import { FooterContainer } from '../containers/footer';
import img from './../assets/images/numbers2.jpg';

const useStyles = makeStyles(theme => ({
    appMain: {
        // paddingLeft: '250px',
        // width: '100%'
    },
    root: {
        backgroundColor: '#fafafa',
    },
    btn: {
        padding: theme.spacing(1)
    },
    menu: {
        display: 'flex',
        top: '60px',
        position: 'fixed',
        width: '80%',
        zIndex: 2,
        backgroundColor: '#fafafa',
    },
    splash: {
        width: '60%',
        height: 'auto',
        // position: 'absolute',
        // top: '13rem',
        // left: '20%',
        opacity: '0.7',
        margin: '20px auto 0 auto',
        '@media(max-width: 700px)': {
            width: '90%',
            left: '5%',
        }
    },
    splashImage: {
        width: '60%',
        height: 'auto',
        // position: 'absolute',
        // top: '13rem',
        // left: '20%',
        opacity: '0.7',
        maxWidth: '100%',
        '@media(max-width: 700px)': {
            width: '90%',
            left: '5%',
        }
    },
    headline: {
        textAlign: 'center',
        padding: '5px',
        // marginTop: '47vw',
        fontSize: '2rem',
        width: '100%',
        '@media(max-width: 820px)': {
            fontSize: '1.8rem',
            // marginTop: '60vw',
        },
        '@media(max-width: 420px)': {
            fontSize: '1.5rem',
            // marginTop: '105vw',
        },
    },
    headline1: {
        color: 'black',
        fontSize: '30px',
        position: 'absolute',
        left: '6%',
        top: '14rem',
        '@media(max-width: 700px)': {
            // fontSize: '30px',
            // top: '8rem',
            // left: '3%',
            color: '#fafafa',
        }
    },
    imgFluid: {
        display: 'block',
        maxWidth: '80%',
        height: '350px',
        width: 'auto',
        margin: '0 auto',
        opacity: '0.4',
        padding: '10px 0 10px 0',
        '@media(max-width: 420px)': {
            maxWidth: '80%',
            height: '250px',
            width: 'auto',
        }
    },
    cardItem: {
        background: 'linear-gradient(-45deg, #6a219a, white, white, #6a219a)',
        textAlign: 'center',
        color: '#ac9551',
        fontWeight: 'bold',
    },
    cardContent: {
        color: '#6a219a',
        margin: '0px',
        fontSize: '1.05rem',
    },
    cardButton: {
        color: '#ac9551',
        fontWeight: 'bold',
        display: 'block',
        margin: '0 auto!important',
        textDecoration: 'none',
    },
}))



export default function MessageGrid() {

    const classes = useStyles();
    const [isScrolling, setIsScrolling] = useState(false);
    const [ start, setStart ] = useState(true);
    const [count, setCount] = useState(0);
    const [count1, setCount1] = useState(0);
    const [count2, setCount2] = useState(0);
    const [count3, setCount3] = useState(0);
    const [count4, setCount4] = useState(0);
    const [count5, setCount5] = useState(0);
    const [count6, setCount6] = useState(0);
    const [count7, setCount7] = useState(0);
    const limit = 2000; // Altere o limite conforme necessário
    const limit1 = 700;
    const limit2 = 46800;
    const limit3 = 2600;
    const limit4 = 1200;
    const limit5 = 1200;
    const limit6 = 990;
    const limit7 = 3960;
    const incrementAmount1 = 5;
    const incrementAmount = 50;

    const handleScroll = () => {
        setIsScrolling(true);
    };

    useEffect(() => {
        const handleIncrement = () => {
            if (count < limit && isScrolling) {
                if (limit > 10000) {
                    setCount((prevCount) => prevCount + incrementAmount);
                } else {
                    setCount((prevCount) => prevCount + incrementAmount1);
                }

            }
            if (count1 < limit1 && isScrolling) {
                if (limit1 > 10000) {
                    setCount1((prevCount1) => prevCount1 + incrementAmount);
                } else {
                    setCount1((prevCount1) => prevCount1 + incrementAmount1);
                }
            }
            if (count2 < limit2 && isScrolling) {
                if (limit2 > 10000) {
                    setCount2((prevCount2) => prevCount2 + incrementAmount);
                } else {
                    setCount2((prevCount2) => prevCount2 + incrementAmount1);
                }
            }
            if (count3 < limit3 && isScrolling) {
                if (limit3 > 10000) {
                    setCount3((prevCount3) => prevCount3 + incrementAmount);
                } else {
                    setCount3((prevCount3) => prevCount3 + incrementAmount1);
                }
            }
            if (count4 < limit4 && isScrolling) {
                if (limit4 > 10000) {
                    setCount4((prevCount4) => prevCount4 + incrementAmount);
                } else {
                    setCount4((prevCount4) => prevCount4 + incrementAmount1);
                }
            }
            if (count5 < limit5 && isScrolling) {
                if (limit5 > 10000) {
                    setCount5((prevCount5) => prevCount5 + incrementAmount);
                } else {
                    setCount5((prevCount5) => prevCount5 + incrementAmount1);
                }
            }
            if (count6 < limit6 && isScrolling) {
                if (limit6 > 10000) {
                    setCount6((prevCount6) => prevCount6 + incrementAmount);
                } else {
                    setCount6((prevCount6) => prevCount6 + incrementAmount1);
                }
            }
            if (count7 < limit7 && isScrolling) {
                if (limit7 > 10000) {
                    setCount7((prevCount7) => prevCount7 + incrementAmount);
                } else {
                    setCount7((prevCount7) => prevCount7 + incrementAmount1);
                }
            }
        };

        const intervalId = setInterval(handleIncrement, 1);

        return () => {
            clearInterval(intervalId);
        };
    }, [count, count1, count2, count3, count4, count5, count6, count7, isScrolling, start]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [show, setShow] = useState(false);
    const url = window.location.href.split('#/')[1];
    useEffect(() => {
        if (url === 'numbers') {
            setShow(true);
            setStart(!start);
        }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        // eslint-disable-next-line
    }, [])

    const handleStart = () => {
        console.log('Start', start);
        setCount(0);
        setCount1(0);
        setCount2(0);
        setCount3(0);
        setCount4(0);
        setCount5(0);
        setCount6(0);
        setCount7(0);
        setStart(!start);
        setIsScrolling(true);
    }

    return (
        <>
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                    display: 'block',
                    marginTop: '78px',
                    // position: 'absolute',
                    // top: '5.5rem',
                    maxWidth: '100%',
                    width: '100%',
                    height: 80,
                },
            }}
        >
            <Paper elevation={12} sx={{ display: 'flex', textAlign: 'center' }} className="paper-back">
                <Typography className="title" variant="h4">{i18n.t(k.NUMBERS)}</Typography>
                    <Typography className="subtitle" variant="h5" sx={{ fontSize: { 'sm': '1.2rem', 'md': '1.5' } }}>{i18n.t(k.NUMBERS_HEAD)}</Typography>
            </Paper>
        </Box>
            <Tooltip title={i18n.t(k.COUNT)} placement="left">
            <img src={img} alt={'Numbers'}className={classes.imgFluid} onClick={handleStart}></img>
            </Tooltip>
        <Paper style={{ height: 'auto', padding: '16px', background: '#eee2f5' }}>
            <Grid container spacing={2} sx={{ marginTop: '-1px'}} >
                    <Grid item xs={12} sm={6} md={3} sx={{
                        display: 'block',
                        padding: '16px',
                        boxShadow: '2px 2px 5px black',
                    }}>
                    {/* Field 1 */}
                    <Typography variant="h2" sx={{ background: '#dcc4ec', color: purple[800], textAlign: 'center', fontWeight: '900', textShadow: '2px 2px 4px black' }}>{count}

                    </Typography>
                        <Typography variant="h2" sx={{ background: '#dcc4ec', color: purple[800], textAlign: 'center', fontSize: { xs: '2.2rem' }, fontWeight: 900 }}>{i18n.t(k.SATISFEITO)}

                    </Typography>
                </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={{
                        display: 'block',
                        padding: '16px',
                        boxShadow: '2px 2px 5px black',
                    }}>
                    {/* Field 2 */}
                        <Typography variant="h2" sx={{ background: '#dcc4ec', color: purple[800], textAlign: 'center', fontWeight: '900', textShadow: '2px 2px 4px black' }}>{count1}

                    </Typography>
                        <Typography variant="h2" sx={{ background: '#dcc4ec', color: purple[800], textAlign: 'center', fontSize: { xs: '2.2rem' }, fontWeight: 900 }}>{i18n.t(k.PAVLOVAS)}</Typography>
                </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={{
                        display: 'block',
                        padding: '16px',
                        boxShadow: '2px 2px 5px black',
                    }}>
                    {/* Field 3 */}
                        <Typography variant="h2" sx={{ background: '#dcc4ec', color: purple[800], textAlign: 'center', fontWeight: '900', textShadow: '2px 2px 4px black' }}>{count2}

                    </Typography>
                        <Typography variant="h2" sx={{ background: '#dcc4ec', color: purple[800], textAlign: 'center', fontSize: { xs: '2.2rem' }, fontWeight: 900 }}>{i18n.t(k.LEMBRANCINHA)}</Typography>
                </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={{
                        display: 'block',
                        padding: '16px',
                        boxShadow: '2px 2px 5px black',
                    }}>
                    {/* Field 4 */}
                        <Typography variant="h2" sx={{ background: '#dcc4ec', color: purple[800], textAlign: 'center', fontWeight: '900', textShadow: '2px 2px 4px black' }}>{count3}

                    </Typography>
                        <Typography variant="h2" sx={{ background: '#dcc4ec', color: purple[800], textAlign: 'center', fontSize: { xs: '2.2rem' }, fontWeight: 900 }}>{i18n.t(k.PIRULITOS)}</Typography>
                </Grid>

                    <Grid item xs={12} sm={6} md={3} sx={{
                        display: 'block',
                        padding: '16px',
                        boxShadow: '2px 2px 5px black',
                    }}>

                        <Typography variant="h2" sx={{ background: '#dcc4ec', color: purple[800], textAlign: 'center', fontWeight: '900', textShadow: '2px 2px 4px black' }}>{parseInt(count4 * 2.20462)} / {count4}</Typography>

                        <Typography variant="h2" sx={{ background: '#dcc4ec', color: purple[800], textAlign: 'center', fontSize: { xs: '2.2rem' }, fontWeight: 900 }}>{i18n.t(k.KG)}</Typography>
                </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={{
                        display: 'block',
                        padding: '16px',
                        boxShadow: '2px 2px 5px black',
                    }}>
                        <Typography variant="h2" sx={{ background: '#dcc4ec', color: purple[800], textAlign: 'center', fontWeight: '900', textShadow: '2px 2px 4px black' }}>{count5}

                    </Typography>
                        <Typography variant="h2" sx={{ background: '#dcc4ec', color: purple[800], textAlign: 'center', fontSize: { xs: '2.2rem' }, fontWeight: 900 }}>{i18n.t(k.OVOS)}</Typography>
                </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={{
                        display: 'block',
                        padding: '16px',
                        boxShadow: '2px 2px 5px black',
                    }}>

                        <Typography variant="h2" sx={{ background: '#dcc4ec', color: purple[800], textAlign: 'center', fontWeight: '900', textShadow: '2px 2px 4px black' }}>{count6}

                    </Typography>
                        <Typography variant="h2" sx={{ background: '#dcc4ec', color: purple[800], textAlign: 'center', fontSize: { xs: '2.2rem' }, fontWeight: 900 }}>{i18n.t(k.BATEDEIRA)}</Typography>
                </Grid>
                    <Grid item xs={12} sm={6} md={3} sx={{
                        display: 'block',
                        padding: '16px',
                        boxShadow: '2px 2px 5px black',
                    }}>

                        <Typography variant="h2" sx={{ background: '#dcc4ec', color: purple[800], textAlign: 'center', fontWeight: '900', textShadow: '2px 2px 4px black' }}>{count7}

                    </Typography>
                    <Typography variant="h2" sx={{ background: '#dcc4ec', color: purple[800], textAlign: 'center', fontSize: { xs: '2.2rem' }, fontWeight: 900 }}>{i18n.t(k.FORNO)}</Typography>
                </Grid>
            </Grid>
        </Paper>
        {show ? <FooterContainer className="footer--pin" /> : null}
        </>
    );
};