import styled from 'styled-components'

export const Container = styled.div`
    padding: 30px 30px;
    margin-top: 30px;
    background: linear-gradient(-45deg, #6a1b9a, white, white, #6a1b9a);
`
export const Wrapper = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 650px;
        margin: 0 auto;
`

export const Row = styled.div`
        display: grid;
        grid-template-columns: 6fr 6fr; repeat(auto-fill, minmax(240px, 1fr));
        grid-gap: 10px;

        @media (max-width: 1000px) {
            grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        }
`

export const Column = styled.div`
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-left: 10px;
`

export const Link = styled.a`
        color: #6a219a;
        margin-bottom: 20px;
        font-size: 18px;
        text-decoration: none;
        &:hover {
            color: #ff9c00;
            transition: 200ms ease-in;
        }
`

export const Copy = styled.span`
        color: #6a219a;;
        margin-bottom: 20px;
        font-size: 13px;
        text-decoration: none;
        text-align: center;
        &:hover {
            color: #ff9c00;
            transition: 200ms ease-in;
        }
`

export const Title = styled.p`
        font-size: 24px;
        color: #6a219a;;
        margin-bottom: 40px;
        font-weight: bold;
`

