import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
//import { Trans } from 'react-i18next';
import k from "../i18n/keys";
import ReactGA from 'react-ga4';
import { makeStyles } from "@material-ui/core";
import { Paper, Grid, Typography } from '@mui/material';
//import { purple } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
//import CardActions from '@mui/material/CardActions';
//import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { FooterContainer } from '../containers/footer';
import img from './../assets/images/specialties_catalogue.png';
import portfolio1 from './../assets/images/carousel1.png';
import portfolio2 from './../assets/images/carousel2.png';
import portfolio3 from './../assets/images/carousel3.png';
import portfolio4 from './../assets/images/carousel4.png';
import portfolio5 from './../assets/images/carousel5.png';
import portfolio6 from './../assets/images/carousel6.png';
import portfolio7 from './../assets/images/carousel7.png';
import portfolio8 from './../assets/images/carousel8.png';
import portfolio9 from './../assets/images/carousel9.png';

const useStyles = makeStyles(theme => ({
    appMain: {
        // paddingLeft: '250px',
        // width: '100%'
    },
    root: {
        backgroundColor: '#fafafa',
    },
    btn: {
        padding: theme.spacing(1)
    },
    menu: {
        display: 'flex',
        top: '60px',
        position: 'fixed',
        width: '80%',
        zIndex: 2,
        backgroundColor: '#fafafa',
    },
    splash: {
        width: '60%',
        height: 'auto',
        // position: 'absolute',
        // top: '13rem',
        // left: '20%',
        opacity: '0.7',
        margin: '20px auto 0 auto',
        '@media(max-width: 700px)': {
            width: '90%',
            left: '5%',
        }
    },
    splashImage: {
        width: '60%',
        height: 'auto',
        // position: 'absolute',
        // top: '13rem',
        // left: '20%',
        opacity: '0.7',
        maxWidth: '100%',
        '@media(max-width: 700px)': {
            width: '90%',
            left: '5%',
        }
    },
    headline: {
        textAlign: 'center',
        padding: '5px',
        // marginTop: '47vw',
        fontSize: '2rem',
        width: '100%',
        '@media(max-width: 820px)': {
            fontSize: '1.8rem',
            // marginTop: '60vw',
        },
        '@media(max-width: 420px)': {
            fontSize: '1.5rem',
            // marginTop: '105vw',
        },
    },
    headline1: {
        color: 'black',
        fontSize: '30px',
        position: 'absolute',
        left: '6%',
        top: '14rem',
        '@media(max-width: 700px)': {
            // fontSize: '30px',
            // top: '8rem',
            // left: '3%',
            color: '#fafafa',
        }
    },
    imgFluid: {
        display: 'block',
        maxWidth: '80%',
        height: '350px',
        width: 'auto',
        margin: '0 auto',
        opacity: '0.6',
        padding: ' 0px 0 10px 0',
        '@media(max-width: 420px)': {
            maxWidth: '80%',
            height: '250px',
            width: 'auto',
        }
    },
    cardItem: {
        // background: '#6a219a',
        background: 'linear-gradient(-45deg, #6a219a, white, white, #6a219a)',
        textAlign: 'center',
        color: '#ac9551',
        fontWeight: 'bold',
    },
    cardContent: {
        color: '#6a219a',
        margin: '0px',
        fontSize: '1.05rem',
    },
    cardButton: {
        color: '#ac9551',
        fontWeight: 'bold',
        display: 'block',
        margin: '0 auto!important',
        textDecoration: 'none',
    },
}))




export default function Portfolio() {

    const classes = useStyles();
    const [ items ] = useState(9);
    const portfolio = [
        portfolio1,
        portfolio2,
        portfolio3,
        portfolio4,
        portfolio5,
        portfolio6,
        portfolio7,
        portfolio8,
        portfolio9
    ];
    const [ show, setShow ] = useState(false);
    const url = window.location.href.split('#/')[1];
    useEffect(() => {
        if (url === 'portfolio') {
            setShow(true);
        }
        ReactGA.send({ hitType: "pageview", page: "/portfolio", title: "Boutique Portfolio" });
        ReactGA.event({
            category: "Boutique Portfolio",
            action: "User Access to Portfolio",
        });
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        // eslint-disable-next-line
    }, [])


    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        display: 'block',
                        marginTop: '78px',
                        marginBottom: '10px',
                        // position: 'absolute',
                        // top: '5.5rem',
                        maxWidth: '100%',
                        width: '100%',
                        height: 80,
                    },
                }}
            >
                <Paper elevation={12} sx={{ display: 'flex', textAlign: 'center' }} className="paper-back">
                    <Typography className="title" variant="h4">{i18n.t(k.BOUTIQUE_PORTFOLIO)}</Typography>
                    <Typography className="subtitle" variant="h5">{i18n.t(k.PORTFOLIO)}</Typography>
                </Paper>
            </Box>
            <img src={img} alt={'Especialidades'} className={classes.imgFluid}></img>
            <Paper style={{ height: 'auto', padding: '16px' }}>
                <Grid container spacing={4} >
                    {Array.from(Array(items), (e, i) => {

                        return <Grid key={i} item xs={12} sm={12} md={4} sx={{ height: 'auto' }}>

                            <Card key={i} className="home-card" sx={{ background: '#fdedeb',  height: { xs: 'auto', xl: '450px' }, marginLeft: { md: '10px' }, boxShadow: '2px 2px 10px #6a219a' }}>
                            <Typography
                                className={classes.cardItem}
                                gutterBottom
                                variant="h5"
                                component="div"
                            >
                                    {/* <strong>{i18n.t(`CLIENTE${i + 1}`)}</strong> */}
                                {i18n.t(k.PRODUTO)} {`${i+1}`}

                            </Typography>
                            <CardMedia
                                className={classes.imgFluid}
                                sx={{
                                    width: '600px',
                                    height: '300px',
                                    display: 'block',
                                    margin: '0 auto',
                                    opacity: '1',
                                }}
                                image= {portfolio[i]}

                                title="Portfolio"
                            />
                        </Card>
                    </Grid>
                    })}
                </Grid>
            </Paper>

            {show ? <FooterContainer className="footer--pin" /> : null}
        </>
    );
};