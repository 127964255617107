import React, { useEffect } from 'react'
import i18n from 'i18next';
import k from "../i18n/keys";
import ReactGA from 'react-ga4';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import { purple } from '@mui/material/colors';
import Card from '@mui/material/Card';
//import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from "@material-ui/core/Typography";
import { FooterContainer } from '../containers/footer';
import { makeStyles } from "@material-ui/core";



const useStyles = makeStyles(theme => ({
    appMain: {
        // paddingLeft: '250px',
        // width: '100%'
    },
    root: {
        backgroundColor: '#fafafa',
    },

    splash: {
        width: '60%',
        height: 'auto',
        // position: 'absolute',
        // top: '13rem',
        // left: '20%',
        opacity: '0.7',
        margin: '20px auto 0 auto',
        '@media(max-width: 700px)': {
            width: '90%',
            left: '5%',
        }
    },
    splashImage: {
        width: '60%',
        height: 'auto',
        display: 'block',
        margin: '0 auto',
        // position: 'absolute',
        // top: '13rem',
        // left: '20%',
        opacity: '0.7',
        maxWidth: '100%',
        '@media(max-width: 700px)': {
            width: '90%',
            left: '5%',
        }
    },
    headline: {
        textAlign: 'center',
        padding: '5px',
        // marginTop: '47vw',
        fontSize: '2.5rem',
        width: '100%',
        '@media(max-width: 820px)': {
            fontSize: '1.8rem',
            // marginTop: '60vw',
        },
        '@media(max-width: 420px)': {
            fontSize: '1.5rem',
            // marginTop: '105vw',
        },
    },
    headline1: {
        textAlign: 'center',
        color: '#6a219a',
        fontSize: '20px',
        paddingTop: '15px',
        // position: 'absolute',
        // left: '6%',
        // top: '14rem',
        '@media(max-width: 700px)': {
            // fontSize: '30px',
            // top: '8rem',
            // left: '3%',
            //color: '#fafafa',
        }
    },
    imgFluid: {
        maxWidth: '25%',
        height: 'auto',
        margin: '0 auto',
        display: 'block',
    },
}))

export default function FormasEntrega() {

    const classes = useStyles();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/formas", title: "Boutique Formas de Entrega" });
        ReactGA.event({
            category: "Boutique Formas de Entrega",
            action: "User Access to Formas de Entrega",
        });

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        // eslint-disable-next-line
    }, [])

  return (
      <>
          <Box
              sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  '& > :not(style)': {
                      display: 'block',
                      marginTop: '78px',
                      // position: 'absolute',
                      // top: '5.5rem',
                      maxWidth: '100%',
                      width: '100%',
                      height: 80,
                  },
              }}
          >
              <Paper elevation={0} sx={{ display: 'flex', textAlign: 'center' }} className="paper-back">
                  <Typography className="title" variant="h4">{i18n.t(k.BOUTIQUE)}</Typography>
                  <Typography className="subtitle" variant="h5">{i18n.t(k.FORMAS)}</Typography>
              </Paper>
          </Box>

          <Card sx={{ background: '#eee2f5', maxWidth: 1200, m: '0 auto', color: '#6a219a', margin: '20px auto 0 auto' }}>

              <CardContent>
                  <Typography gutterBottom variant="h3" component="div" className={classes.headline}>
                      {i18n.t(k.FORMAS_HEAD)}
                  </Typography>
                  <Typography gutterBottom variant="h4" component="div" className={classes.headline}>
                      {i18n.t(k.FORMAS_SUBHEAD)}
                  </Typography>
                  <Typography variant="h5" sx={{ color: purple[800], fontSize: '1.1rem' }}>
                      {i18n.t(k.FORMAS_TEXTO1)}
                  </Typography>
                  <br />
                  <Typography variant="h5" sx={{ color: purple[800], fontSize: '1.1rem' }}>
                      {i18n.t(k.FORMAS_TEXTO2)}
                  </Typography>
                  <br />
                  <Typography variant="h5" sx={{ color: purple[800], fontSize: '1.1rem' }}>
                      {i18n.t(k.FORMAS_TEXTO3)}
                  </Typography>
                  <br />
                  <Typography variant="h5" sx={{ color: purple[800], fontSize: '1.1rem' }}>
                      {i18n.t(k.FORMAS_TEXTO4)}
                  </Typography>
                  <br />
                  <Typography variant="h5" sx={{ color: purple[800], fontSize: '1.1rem' }}>
                      {i18n.t(k.FORMAS_TEXTO5)}
                  </Typography>
              </CardContent>
              <CardMedia
                  className={classes.splashImage}
                  sx={{ height: 200, width: '30%' }}
                  image="./images/Delivery.jpg"
                  title="Entrega"
              />
              {/* <CardActions>
                  <Button size="small" href={site} target="_blank" rel="noopener noreferrer" sx={{ color: '#ac9551', textAlign: 'center' }}>Saiba mais</Button>
                  <Button size="small" href="#/" sx={{ color: '#ac9551', textAlign: 'center' }}>Retornar</Button>
              </CardActions> */}
              <Typography variant="h5" sx={{ color: purple[800], fontSize: '1.1rem' }} className={classes.headline1} >
                  EMAIL: {i18n.t(k.EMAIL_ADDRESS)}
              </Typography>
              <Typography variant="h5" sx={{ color: purple[800], fontSize: '1.1rem' }} className={classes.headline1} >
                  <a href="https://wa.me/5511990052502" target="_blank" rel="noopener noreferrer">{i18n.t(k.WHATSAPP)}</a>
              </Typography>
              <br />
          </Card>


          <FooterContainer className="footer--pin" />
      </>
  )
}
