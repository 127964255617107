import i18n from 'i18next';
import k from "../i18n/keys";
// import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import { Paper, Tooltip } from '@mui/material';
import { Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { purple } from '@mui/material/colors';
import Typography from "@material-ui/core/Typography";
import Carousel from 'react-material-ui-carousel'
import ReactGA from 'react-ga4';


// ============ Image Imports =============

import car1 from './../assets/images/carousel1.png';
import car2 from './../assets/images/carousel2.png';
import car3 from './../assets/images/carousel3.png';
import car4 from './../assets/images/carousel4.png';
import car5 from './../assets/images/carousel5.png';
import car6 from './../assets/images/carousel6.png';
import car7 from './../assets/images/carousel7.png';
import car8 from './../assets/images/carousel8.png';
import car9 from './../assets/images/carousel9.png';
//import Telematics from './../components/Telematics';
import Cards from './Cards';
import MessageGrid from './MessageGrid';
import Avaliacoes from './Avaliacoes';
import Portfolio from './Portfolio';
import { FooterContainer } from '../containers/footer';
import { FaWhatsapp } from 'react-icons/fa';
import QRCode from 'react-qr-code';
// ============ OEM Component ==========


const useStyles = makeStyles(theme => ({
    appMain: {
        // paddingLeft: '250px',
        // width: '100%'
    },
    root: {
        backgroundColor: '#fafafa',
    },
    btn: {
        padding: theme.spacing(1)
    },
    menu: {
        display: 'flex',
        top: '60px',
        position: 'fixed',
        width: '80%',
        zIndex: 2,
        backgroundColor: '#fafafa',
    },
    splash: {
        width: '60%',
        height: 'auto',
        // position: 'absolute',
        // top: '13rem',
        // left: '20%',
        opacity: '0.7',
        margin: '20px auto 0 auto',
        '@media(max-width: 700px)': {
            width: '90%',
            left: '5%',
        }
    },
    splashImage: {
        width: '60%',
        height: 'auto',
        // position: 'absolute',
        // top: '13rem',
        // left: '20%',
        opacity: '0.7',
        maxWidth: '100%',
        '@media(max-width: 700px)': {
            width: '90%',
            left: '5%',
        }
    },
    headline: {
        textAlign: 'center',
        padding: '5px',
        // marginTop: '47vw',
        fontSize: '2rem',
        width: '100%',
        '@media(max-width: 820px)': {
            fontSize: '1.8rem',
            // marginTop: '60vw',
        },
        '@media(max-width: 420px)': {
            fontSize: '1.5rem',
            // marginTop: '105vw',
        },
    },
    headline1: {
        color: 'ç',
        fontSize: '30px',
        position: 'absolute',
        left: '6%',
        top: '14rem',
        '@media(max-width: 700px)': {
            // fontSize: '30px',
            // top: '8rem',
            // left: '3%',
            color: '#fafafa',
        }
    },
    imgFluid: {
        maxWidth: '100%',
        height: 'auto',
    },
    imgCarousel: {
        boxShadow: '2px 2px 5px black',
    },
    cardItem: {
        background: '#6a219a',
        // 'linear-gradient(-45deg, #6a219a, white, white, #6a219a)',
        textAlign: 'center',
        color: '#ac9551',
        fontWeight: 'bold',
    },
    cardContent: {
        color: '#6a219a',
        margin: '0px',
        fontSize: '1.05rem',
    },
    cardButton: {
        color: '#ac9551',
        fontWeight: 'bold',
        display: 'block',
        margin: '0 auto!important',
        textDecoration: 'none',
    },
}))


export default function Home(props) {

    //const { t } = useTranslation();

    const classes = useStyles();
    const [ state, setState ] = useState({});

    const getGeoInfo = () => {
        axios.get('https://ipapi.co/json/').then((response) => {
            let data = response.data;
            setState({
                countryName: data.country_name,
                region: data.region,
                city: data.city,
                ip: data.ip
            });
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        getGeoInfo();
    }, [])

    const { countryName, region, city, ip } = state;

    if ( countryName ){
    ReactGA.event({
        category: "From",
        action: `Access from ${countryName}, - ${city} - ${region} - IP:${ip}`,
        labels: 'Boutique Home IP'
    });
}

    const [isHovered, setHovered] = useState(false);
    const phoneNumber = '5511990052502?text=Estou%20visitando%20o%20site%20da%20Boutique%20do%20Suspiro';
    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };


    const openWhatsApp = () => {
        const whatsappUrl = `https://wa.me/${phoneNumber}`;
        window.open(whatsappUrl, '_blank');
    };



    const params = useParams();
    useEffect(() => {
        if (params && params.lng && sessionStorage.getItem('lng') !== params.lng) {
            sessionStorage.setItem('lng', params.lng);
            props.changeLanguage(params.lng);
        }
    // eslint-disable-next-line
    }, [])

    function Item(props) {
        return (
            <Paper elevation={0} className="carousel-home-title" sx={{ color: '#6ca4b5', fontWeight: 900 }}>
                <img src={props.item.image} alt={props.item.alt} className="oem-carousel"></img>
                <span id="carousel-id" className="carousel-title">{props.item.name}</span>
                {/* <p>{props.item.description}</p> */}
            </Paper>
        )
    }

    var items = [
        {
            name: i18n.t(k.SLOGAN0),
            description: "Suspiros Coloridos",
            image:  car1,
            alt: "Suspiros Coloridos"
        },
        {
            name: i18n.t(k.SLOGAN1),
            description: "Pavlova",
            image: car2,
            alt: "Pavlova"
        },
        {
            name: i18n.t(k.SLOGAN2),
            description: "Personalizados",
            image: car3,
            alt: "Personalizados"
        },
        {
            name: i18n.t(k.SLOGAN3),
            description: "Torres",
            image: car4,
            alt: "Torres"
        },
        {
            name: i18n.t(k.SLOGAN4),
            description: "Presentes",
            image: car5,
            alt: "Presentes"
        },
        {
            name: i18n.t(k.SLOGAN5),
            description: "Bonecas",
            image: car6,
            alt: "Bonecas"
        },
        {
            name: i18n.t(k.SLOGAN6),
            description: "Batizados",
            image: car7,
            alt: "Batizados"
        },
        {
            name: i18n.t(k.SLOGAN7),
            description: "Potinhos",
            image: car8,
            alt: "Potinhos"
        },
        {
            name: i18n.t(k.SLOGAN8),
            description: "Pirulitos",
            image: car9,
            alt: "Pirulitos"
        }
    ]
    return (

        <React.Fragment>

            <div className="whatsapp-icon" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <FaWhatsapp onClick={openWhatsApp} />
                {isHovered && (
                    <div className="qr-code">
                        <QRCode value={`https://wa.me/${phoneNumber}`} size={130} />
                    </div>
                )}
            </div>


            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        display: 'block',
                        marginTop: '78px',
                        marginBottom: '20px',
                        // position: 'absolute',
                        // top: '5.5rem',
                        maxWidth: '100%',
                        width: '100%',
                        height: 80,
                    },
                }}
            >
            <Paper elevation={12} sx={{ display: 'flex', textAlign: 'center' }} className="paper-back">
                <Typography className="title" variant="h4">{i18n.t(k.BOUTIQUE)}</Typography>
                <Typography className="subtitle" variant="h5">{i18n.t(k.SLOGAN)}</Typography>
            </Paper>
            </Box>

            <div className="content-container">
                <Box className={classes.headline}>
                    <Tooltip title="Boutique Whatsapp" >
                    <Button className="side-text" onClick={openWhatsApp}>
                        {i18n.t(k.SIDE_TEXT)}
                    </Button>
                    </Tooltip>
                    <Carousel className="carousel"
                    indicators={false}
                    animation='fade'
                    fullHeightHover={false}
                    interval={4000}
                        navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                            style: {
                                backgroundColor: '#ac9551',
                                borderRadius: '5px',
                                color: purple[900],
                                fontWeight: 100,

                            }
                        }}
                        navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                            style: {
                                bottom: '0',
                                //top: 'unset'
                            }
                        }}
                        NextIcon='next'             // Change the "inside" of the next button to "next"
                        PrevIcon='prev'             // Change the "inside of the prev button to "prev"
                    NavButton={({ onClick, className, style, next, prev }) => {

                        return (
                            <Button onClick={onClick} className={className} style={style}>
                                {next && <ChevronRightIcon></ChevronRightIcon>}
                                {prev && <ChevronLeftIcon></ChevronLeftIcon>}
                            </Button>
                        )
                    }}
                    >
                    {
                        items.map((item, i) => <Item className={classes.imgCarousel} key={i} item={item} />)
                    }
                    </Carousel>
            </Box>

            <Cards></Cards>
            <MessageGrid></MessageGrid>
            <Portfolio></Portfolio>
            <Avaliacoes></Avaliacoes>

            <FooterContainer className="footer--pin" />
            </div>

        </React.Fragment>
    );
}