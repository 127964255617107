import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import i18n from 'i18next';
import k from "../i18n/keys";
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import Typography from "@material-ui/core/Typography";
import { FooterContainer } from '../containers/footer';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import contact from '../assets/images/contact_purple.png';
import ReactGA from 'react-ga4';

export default function Contact() {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/contact", title: "Boutique Contact" });
        ReactGA.event({
            category: "Boutique Contact",
            action: "User Access to Contact",
        });

    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },[])

    const [state, setState] = useState({
        name: "",
        lastname: "",
        email: '',
        phone: '',
        reason: '',
        message: ''
    })

    const handleSubmit = (event) => {
        event.preventDefault();

        var template_params = {
            name: state.name,
            lastname: state.lastname,
            phone: state.phone,
            from_name: state.email,
            email: state.email,
            to_name: 'Olá time da Boutique do Suspiro',
            subject: 'Visitou a Boutique do Suspiro',
            reason: state.reason,
            message_html: state.message,
            message: 'Favor clicar no código para confirmar',
        }

        var service_id = "contact_service";
        var template_id = "template_ex836fg";
        var user_id = 'G6jYL3-Jxy_YNy4Xz'; //'2FZ9hkxvRt-6Y4sVG'; //user_7kPkOhEo0c6N7DHtmJ9o7'


        if ((state.name === '') || (state.email === '') || (state.phone === '') || (state.reason === '')) {
            document.getElementById('message-error').innerHTML = "Por favor entre com os dados marcados com *!";
        } else {
            emailjs.send(service_id, template_id, template_params, user_id)
                .then((result) => {
                    //console.log(result.text);
                    document.getElementById('message-error').innerHTML = "Email Enviado!";
                    resetForm();
                    setTimeout(() => {
                        document.getElementById('message-error').innerHTML = "";
                    }, 5000);
                }, (error) => {
                    console.log(error.text);
                });
        }
    }

    const handleChange = ( name, event ) => {
        const value = event.target.value;
        setState({
            ...state,
            [event.target.name]: value
        });
    }

    const resetForm = () => {
        setState({ name: '', lastname: '', email: '', phone: '', reason: '', message: '' });
    }

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        display: 'block',
                        marginTop: '78px',
                        // position: 'absolute',
                        // top: '5.5rem',
                        maxWidth: '100%',
                        width: '100%',
                        height: 80,
                    },
                }}
            >
                <Paper elevation={0} sx={{ display: 'flex', textAlign: 'center' }} className="paper-back">
                    <Typography className="title" variant="h4">{i18n.t(k.BOUTIQUE)}</Typography>
                    <Typography className="subtitle" variant="h5">{i18n.t(k.CONTACT_HEAD)}</Typography>
                </Paper>
            </Box>

            <section id="contact">
                <div className="container-wrap">
                    <br /><br /><br />
                    <div className='contact-header'>{"Entre em Contato"}</div>
                    <img className="contact-image img-fluid" src={contact} alt="Contact us" />
                    <a href="https://wa.me/5511990052502" target="_blank" rel="noopener noreferrer"> <WhatsAppIcon sx={{ color: 'green', display: 'block', margin: '0 auto', fontSize: '7rem' }}></WhatsAppIcon></a>
                </div>

                <div id="form">

                    <form id="waterform" method="POST" onSubmit={handleSubmit}>

                            <div className="grid-contact-item1">
                                <div>
                                    <div className="" id="name-form">
                                    <label className="contact-label" htmlFor="name">{i18n.t(k.NOME)}</label>
                                        <input className="contact-input" type="text" id="name" value={state.name} name="name" onChange={(event) => handleChange('name', event)} autoComplete="new-password" />

                                    </div>

                                    <div className="" id="lastname-form">
                                    <label className="contact-label" htmlFor="lastname">{i18n.t(k.SOBRENOME)}</label>
                                    <input className="contact-input" type="text" id="lastname" value={state.lastname} name="lastname" onChange={(event) => handleChange('lastname', event)} autoComplete="new-password" />
                                    </div>

                                    <div className="" id="email-form">
                                    <label className="contact-label" htmlFor="email">{i18n.t(k.EMAIL)}</label>
                                    <input className="contact-input" type="email" id="email" value={state.email} name="email" onChange={(event) => handleChange('email', event)} autoComplete="new-password" />
                                    </div>

                                    <div className="" id="phone-form">
                                    <label className="contact-label" htmlFor="phone">{i18n.t(k.CELULAR)}</label>
                                    <input className="contact-input" type="text" id="phone" value={state.phone} name="phone" onChange={(event) => handleChange('phone', event)} autoComplete="new-password" />
                                    </div>

                                    <div>
                                    <label className="contact-label" htmlFor="sel1">{i18n.t(k.RAZAO)}</label>
                                    <select className="form-control contact-select" id="sel1" value={state.reason} sx={{ color: 'red' }} name="reason" onChange={(event) => handleChange('reason', event)} autoComplete="new-password">
                                        <option className="contact-input" active="true">{i18n.t(k.SELECIONE)}</option>
                                        <option className="contact-input">{i18n.t(k.ANIVERSARIO)}</option>
                                        <option className="contact-input">{i18n.t(k.BATIZADOS)}</option>
                                        <option className="contact-input">{i18n.t(k.BATIZADOS)}</option>
                                        <option className="contact-input">{i18n.t(k.CASAMENTOS)}</option>
                                        <option className="contact-input">{i18n.t(k.CHADEBEBE)}</option>
                                        <option className="contact-input">{i18n.t(k.CHAREVELACAO)}</option>
                                        <option className="contact-input">{i18n.t(k.CORPORATIVO)}</option>
                                        <option className="contact-input">{i18n.t(k.MATERNIDADE)}</option>
                                        <option className="contact-input">{i18n.t(k.SOBREMESAS)}</option>
                                        <option className="contact-input">{i18n.t(k.OUTROS)}</option>
                                        </select>
                                    </div>

                                    <div>
                                    <label className="contact-label" htmlFor="message">{i18n.t(k.MENSAGEM)}</label>
                                <textarea className="contact-textarea" id="message" value={state.message} name="message" onChange={(event) => handleChange('message', event)} autoComplete="new-password"></textarea>
                                    </div>

                                    <div>
                                    <input className="contact-submit" type="submit" value={i18n.t(k.ENVIAR)} />
                                    </div>

                                    <div id="message-error" className="message-error">
                                        {/* <label id="message-error" className="message-error" htmlFor="message-error" type="text" /> */}
                                    {/* <input className="contact-input" type="hidden" id="message-error" name="message-error" autoComplete="new-password" /> */}
                                    </div>
                                </div>
                            </div>

                    </form>
                </div>

            </section>
            <FooterContainer className="footer--pin" />
        </React.Fragment>);

    }
