import React, { useEffect } from 'react'
import i18n from 'i18next';
// import { Trans } from 'react-i18next';
import k from "../i18n/keys";
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
// import { purple } from '@mui/material/colors';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FooterContainer } from '../containers/footer';
import ReactGA from 'react-ga4';

export default function NotFound() {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/404", title: "Boutique 404" });
        ReactGA.event({
            category: "Boutique 404 Not Found",
            action: "User Access to wrong page 404",
        });

    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    return (
        <>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        display: 'block',
                        marginTop: '78px',
                        // position: 'absolute',
                        // top: '5.5rem',
                        maxWidth: '100%',
                        width: '100%',
                        height: 80,
                    },
                }}
            >
                <Paper elevation={0} sx={{ display: 'flex', textAlign: 'center' }} className="paper-back">
                    <Typography className="title" variant="h4">{i18n.t(k.BOUTIQUE)}</Typography>
                    <Typography className="subtitle" variant="h5" sx={{ fontSize: { 'sm': '1.2rem', 'md': '1.5' } }}>{i18n.t(k.NOTFOUND_HEAD)}</Typography>
                </Paper>
            </Box>

            <Card sx={{ maxWidth: 400, m: '0 auto', color: '#6a219a', margin: '20px auto 0 auto', background: '#eee2f5' }}>
                <CardMedia
                    sx={{ height: 280 }}
                    image="./images/notFound.jpeg"
                    title="Superação"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'center'}}>
                        {i18n.t(k.NOTFOUND_HEAD)}
                    </Typography>

                </CardContent>
                <CardActions>

                    {/* <Button size="small" href="#/" sx={{ color: '#ac9551', textAlign: 'center' }}>
                        <Trans i18nKey="welcomeUser">
                            <strong>{i18n.t(k.VOLTAR)}</strong>
                        </Trans>

                    </Button> */}
                </CardActions>
            </Card>
            <br />
            <FooterContainer className="footer--pin" />
        </>
    )
}

